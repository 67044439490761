import React, { useEffect, useRef, useState } from "react";
import { QRCode } from "react-qrcode-logo";

interface Props {
  content?: string;
  logo?: string;
  lite?: boolean;
}

export const QrGenerator: React.FC<Props> = ({ content, logo, lite }) => {
  // const processLogoUrl = (url: string) => {
  //   // First replace the domain
  //   const updatedUrl = url.replace(
  //     "static.tria.so",
  //     "d2rmfrxuyb3k7o.cloudfront.net"
  //   );

  //   // Then handle the extension
  //   if (updatedUrl.endsWith(".svg")) {
  //     return updatedUrl.slice(0, -4) + ".webp";
  //   } else {
  //     return updatedUrl;
  //   }
  // };

  // const defaultLogo = "/ethereum-eth.webp";

  // const qrCodeRef = useRef(
  //   new QRCodeStyling({
  //     width: 200,
  //     height: 200,
  //     image: logo ? processLogoUrl(logo) : defaultLogo,
  //     dotsOptions: {
  //       color: "black",
  //       type: "rounded",
  //     },
  //     imageOptions: {
  //       margin: 2,
  //     },
  //   })
  // );
  // const divRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   if (divRef.current) {
  //     qrCodeRef.current.append(divRef.current);
  //   }
  // }, []);

  // useEffect(() => {
  //   qrCodeRef.current.update({
  //     data: content || "",
  //     image: logo ? processLogoUrl(logo) : defaultLogo,
  //   });
  // }, [content, logo]);

  return (
    <QRCode
      value={content}
      logoImage={logo}
      size={200}
      qrStyle="fluid"
      eyeRadius={8}
      fgColor="#FFFFFF"
      logoWidth={54}
      logoHeight={54}
      bgColor={lite ? "#101010" : "#2b2b2b"}
      style={{ borderRadius: "12px" }}
      logoOpacity={1}
      removeQrCodeBehindLogo
    />
  );
};
