import { CreateBridgeTxnResponse } from "@tria-sdk/utils";

export async function calculateDebridgeCost(createTxnRes: CreateBridgeTxnResponse) {
    try {

        // const chainToFixFee: { [key: number]: number  } = {
        //   42161: 2.56,
        //   43114: 1.35,
        //   56: 2.95,
        //   1: 2.52,
        //   137: 0.2,
        //   10: 2.52
        // }
        // const DlnProtocolFee = createTxnRes.data?.estimation.costsDetails.find(costDetail => costDetail.type === 'DlnProtocolFee');
        // const AffiliateFee = createTxnRes.data?.estimation.costsDetails.find(costDetail => costDetail.type === 'AffiliateFee');
        // const TakerMarginFee = createTxnRes.data?.estimation.costsDetails.find(costDetail => costDetail.type === 'TakerMargin');
    
        // const DlnFeeAmount = parseFloat(DlnProtocolFee?.payload?.feeAmount ?? '1000000');
        // const AffiliateFeeAmount = parseFloat(AffiliateFee?.payload?.feeAmount ?? '1000000');
        // const TakerMarginFeeAmount = parseFloat(TakerMarginFee?.payload?.feeAmount ?? '1000000');
    
        // const totalCharge =( DlnFeeAmount + AffiliateFeeAmount + TakerMarginFeeAmount) / 10**6;
        // const chainId = createTxnRes.data?.estimation.srcChainTokenIn.chainId || 1;
        // return totalCharge + chainToFixFee[chainId];
        // Given values

        const chainIdToCryptoId: { [key: number]: string } = {
          42161:'ethereum',
          43114: 'avalanche-2',
          56: 'binancecoin',
          1: 'ethereum',
          137: 'matic-network',
          10: 'ethereum'
        };
        const weiAmount = BigInt(createTxnRes.data?.tx.value || "0");
        const chainId = createTxnRes.data?.estimation.srcChainTokenIn.chainId || 1;
        
        const assetPrice = await getAsstetPriceInUsd(chainIdToCryptoId[chainId]);

        // Conversion constants
        const weiToEth = 1e18;

        // Convert Wei to ETH
        const ethAmount = Number(weiAmount)  / weiToEth;

        // Convert ETH to USD and round to 4 decimal places
        const usdAmount = (ethAmount * assetPrice).toFixed(4);

        return Number(usdAmount);

    }catch(err) {
        console.error('Error calculating fees:', err);
        return 0;
    }
  }

  const getAsstetPriceInUsd = async (cryptoId: string) => {
    const url = `https://pro-api.coingecko.com/api/v3/simple/price?ids=${cryptoId}&vs_currencies=usd`;
  const response = await fetch(url, {
    headers: {
      'x-cg-pro-api-key': 'CG-XHndbvjcD5AriyHDPRBSxCwU',
    },
  });
  const data = await response.json();
  return data[cryptoId]['usd'];
  } 