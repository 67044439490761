import React from "react";
import { HomeAssets } from "../Loaders/HomeAssets";

interface HomeCryptoProps {
  responsive?: boolean;
}
export default function HomeCrypto({ responsive }: HomeCryptoProps) {
  return (
    <div className={`${responsive ? "w-full" : "w-full"} grid columns-1  `}>
      <HomeAssets responsive={responsive} />
      <HomeAssets responsive={responsive} />
      <HomeAssets responsive={responsive} />
    </div>
  );
}
