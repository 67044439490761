import { useState } from "react";
import { Names, useDebounce } from "../../../../rx.core";
import { SearchedUserContainer } from "../../components/Containers/user/SearchedUserContainer";
import { useTriaUser } from "../../contexts";

interface Props {
  onUpdate: (results: string) => void;
  liteMode?: boolean;
}

export const SearchLayout: React.FC<Props> = ({ onUpdate, liteMode }) => {
  const [searchValue, setSearchValue] = useDebounce<string>("", handleSearch);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userResults, setUserResults] = useState<Names[]>([]);
  const { searchUser } = useTriaUser();

  async function handleSearch(newValue: string) {
    if (newValue === "") {
      resetSearchState();
      return;
    }

    setIsLoading(true);
    try {
      const searchResult = await searchUser(newValue);
      setUserResults(searchResult?.data);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  const resetSearchState = () => {
    setSearchValue("");
    setUserResults([]);
  };

  const handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchValue(e.target.value);
  onUpdate(searchValue);

  return (
    <div className="self-stretch  flex flex-col">
      <div className="self-stretch h-[5.7vh] justify-start items-center gap-[2vh] inline-flex">
        <div className="grow shrink basis-0 h-[4vh] px-[1.4vh] dark:bg-hoverDarkColor bg-hoverColor  rounded-[3.57vh] justify-start items-center gap-[1.4vh] flex">
          <div className="flex justify-between items-center self-stretch w-full ">
            <input
              className={`${
                liteMode
                  ? "text-white"
                  : "text-fontLightColor dark:text-fontLightColorDark"
              } dark:bg-hoverDarkColor bg-hoverColor text-[1.67vh] outline-none w-[90%]`}
              placeholder="Search User"
              onFocus={(e) => (e.target.placeholder = "")}
              onBlur={(e) => (e.target.placeholder = "Search User")}
              value={searchValue}
              onChange={handleSearchValueChange}
            ></input>
            <div className="w-[2.1vh] h-[2.1vh] relative">
              <img
                src="/icons/search-normal.svg"
                className="w-[2.1vh] h-[2.1vh]"
              ></img>
            </div>
          </div>
        </div>
      </div>
      {userResults?.length > 0 && searchValue?.length > 0 && !isLoading && (
        <div
          className={`${
            liteMode ? "w-full" : "w-[53vh]"
          } bg-primaryColor  pl-[2.4vh] ml-[-2.4vh] dark:bg-primaryDarkColor z-[1000] justify-start flex flex-col gap-[4vh] py-[1.4vh] max-h-[56vh] overflow-auto`}
        >
          {userResults.map((user) => (
            <SearchedUserContainer user={user} liteMode={liteMode} />
          ))}
        </div>
      )}
      {userResults?.length == 0 && searchValue?.length > 0 && !isLoading && (
        <div className=" flex h-[12.4vh] items-center justify-center  overflow-auto text-white text-[2vh] font-[600] ">
          NO USERS FOUND!
        </div>
      )}
    </div>
  );
};
