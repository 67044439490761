import { getWalletStoreLocalStorage } from "../package/rx.core/src/utils/getWalletStoreLocalStorage";

export function useTriaName() {
  const triaName = getWalletStoreLocalStorage()?.triaName ?? "";

  if (!triaName) {
    console.error("Cannot find trianame");
  }

  return { triaName };
}
