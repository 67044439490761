export enum SocialPlatform {
  GOOGLE = "google",
  TWITTER = "twitter",
  APPLE = "apple",
  DISCORD = "discord",
  TELEGRAM = "telegram",
  TELEGRAM_MINIAPP = "telegram_miniapp",
}

export const SocialPlatformNames: Record<SocialPlatform, string> = {
  [SocialPlatform.GOOGLE]: "Google",
  [SocialPlatform.APPLE]: "Apple",
  [SocialPlatform.TWITTER]: "X",
  [SocialPlatform.DISCORD]: "Discord",
  [SocialPlatform.TELEGRAM]: "Telegram",
  [SocialPlatform.TELEGRAM_MINIAPP]: "Telegram",
};
