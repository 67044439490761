import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PrevFetch, log } from "./logs.model";

const INITIAL_STATE: log = {
  apiTimer: {
    getAssetsForTriaName: 0,
    getNFTs: 0,
    recentUsers: 0,
    getAllHistory: 0,
    getNftDetail: {
      prevFetch: {
        chainName: "",
        tokenAddress: "",
      },
      time: 0,
    },
    getAssetDetail: {
      prevFetch: {
        chainName: "",
        tokenAddress: "",
      },
      time: 0,
    },
    getAssetHistory: {
      prevFetch: {
        chainName: "",
        tokenAddress: "",
      },
      time: 0,
    },
    getOnChainConversation: {
      prevWithTriaName: "",
      time: 0,
    },
  },
};

export const logSlice = createSlice({
  name: "apiLogs",
  initialState: INITIAL_STATE,
  reducers: {
    updateApiTimer: (
      state,
      action: PayloadAction<{
        apiEndpoint: string;
        prevFetch?: PrevFetch;
        prevWithTriaName?: string;
        time: number;
      }>
    ) => {
      const { apiEndpoint, prevFetch, prevWithTriaName, time } = action.payload;
      (state.apiTimer as any)[apiEndpoint] = {
        ...(state.apiTimer as any)[apiEndpoint],
        prevFetch,
        prevWithTriaName,
        time,
      };
    },
  },
});

export const { updateApiTimer } = logSlice.actions;
