import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { RootState } from "../package/rx.core";
import { useTriaUser } from "../package/ui.common/src/contexts/tria-user-provider/useTriaUser";
import { useTriaName } from "./useTriaName";
import { GetTotalBalanceResponse } from "@tria-sdk/core";

export const useTotalBalance = (
  params?: Omit<
    UseQueryOptions<GetTotalBalanceResponse["data"]>,
    "queryKey" | "queryFn"
  >
) => {
  const { getTotalBalance } = useTriaUser();
  const { triaName } = useTriaName();
  const userInactive = useSelector(
    (state: RootState) => state.User.isUserInactive
  );

  const query = useQuery<GetTotalBalanceResponse["data"]>({
    queryKey: ["getTotalBalance", triaName],
    queryFn: async () => {
      const resp = await getTotalBalance(triaName);
      if (resp instanceof Error) {
        return Promise.reject(new Error(resp.message));
      }
      const { success, data, message } = resp;
      if (!success) {
        return Promise.reject(new Error(message));
      }
      if (data) {
        return data;
      }
      return Promise.reject(new Error(message));
    },
    staleTime: 5 * 10 * 1000,
    refetchInterval: 10 * 1000,
    enabled: (params?.enabled ?? true) && !!triaName && !userInactive,
    ...params,
  });

  return query;
};
