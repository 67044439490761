import React from "react";
import { NftsItem } from "../../../../rx.core";
import "./index.css";

type Props = {
  key: number;
  id: number;
  loading?: boolean;
  nftItem?: NftsItem;
  onClick: (action: number) => void;
  responsive?: boolean;
};

export const NftCard: React.FC<Props> = ({
  key,
  id,
  loading,
  nftItem,
  onClick: handleNftClick,
  responsive = false,
}) => {
  const handleItemClick = () => {
    handleNftClick(id);
  };

  if (responsive) {
    return (
      <div
        className="w-full bg-primaryColor dark:bg-primaryDarkColor rounded-xl border border-outlining dark:border-outliningDark border-opacity-10 flex flex-col"
        onClick={handleItemClick}
      >
        <div className="relative w-full pb-[100%]">
          <img
            className={`${
              responsive ? "p-2" : ""
            } absolute inset-0 w-full h-full object-cover rounded-t-xl`}
            src={
              nftItem?.nftInfo?.content?.preview?.url ||
              nftItem?.collectionInfo?.content?.icon?.url
            }
            alt={nftItem?.nftInfo?.name || "NFT"}
          />
          <div
            className={`${
              responsive ? "top-4 left-4" : "top-2 left-2"
            } absolute  w-8 h-8 flex items-center justify-center bg-transparent rounded-full`}
          >
            <img
              className="w-5 h-5 rounded-full"
              src={nftItem?.chainLogo}
              alt={nftItem?.chainName || "Chain"}
            />
          </div>
        </div>
        <div className="p-3 flex justify-between items-center">
          <div className="text-fontColorNftCard dark:text-fontColorNftCardDark text-sm font-semibold truncate">
            {nftItem?.nftInfo?.name}
          </div>
          <div className="text-fontLightColor dark:text-fontLightColorDark text-sm font-semibold">
            x{nftItem?.amount}
          </div>
        </div>
      </div>
    );
  }

  // Original non-responsive layout
  return (
    <div
      className="w-full h-[29vh] p-[1vh] bg-primaryColor dark:bg-primaryDarkColor rounded-[1.2vh] border-[0.2vh] border-outlining dark:border-outliningDark border-opacity-[10%] flex-col justify-center items-center gap-[1vh] inline-flex"
      onClick={handleItemClick}
    >
      <div className="w-[20vh] h-[22vh] relative rounded-[0.5vh]">
        <img
          className="w-[20vh] h-[22vh] left-0 top-0 absolute rounded-[0.5vh]"
          src={
            nftItem?.nftInfo?.content?.preview?.url ||
            nftItem?.collectionInfo?.content?.icon?.url
          }
        />
        <div className="w-[4.76vh] h-[4.76vh] absolute top-0 left-0 flex items-center justify-center ">
          <img
            className="w-[2.6vh] h-[2.6vh] bg-transparent rounded-[1.3vh]"
            src={nftItem?.chainLogo}
          />
        </div>
      </div>
      <div className="self-stretch px-[0.7vh] py-[1vh] rounded-[2vh] justify-between items-center gap-[1vh] inline-flex ">
        <div className="h-[2vh] justify-center items-center gap-[1vh] flex">
          <div className="text-center dark:text-fontColorNftCardDark text-fontColorNftCard text-[1.67vh] font-semibold leading-tight">
            {nftItem?.nftInfo?.name}
          </div>
        </div>
        <p className="text-fontLightColor dark:text-fontLightColorDark font-[600] text-[1.67vh]">
          x{nftItem?.amount}
        </p>
      </div>
    </div>
  );
};
