import { useState } from "react";
import { useDispatch } from "react-redux";

import "../../index.css";
import { updateCurrentRoute } from "../../package/rx.core";
import { CryptoLite, Nav, Opentria } from "../../package/ui.common";

import { DisplayCostLite } from "../../package/ui.common/src/components/Cards/DisplayCostCard/DisplayCostCardLite";
import NftLite from "../../package/ui.common/src/layouts/Home/NftLite";
// import { NavLite } from "../../package/ui.common/src/layouts/Navbar/NavBarLite";

function Lite() {
  const dispatch = useDispatch();

  dispatch(updateCurrentRoute(window.location.pathname));
  const [activeTab, setActiveTab] = useState<"crypto" | "nft">("crypto");

  const handleTabChange = (tab: "crypto" | "nft") => {
    setActiveTab(tab);
  };

  const [isAccountsOpen, setIsAccountsOpen] = useState(false);
  const [isQrOpen, setIsQrOpen] = useState(false);
  const [isAccountsExpanded, setAccountsExpanded] = useState(false);

  return (
    <div className="flex flex-col w-screen h-screen bg-primaryColor dark:bg-primaryDarkColorLite font-montserrat">
      <Nav
        lite={true}
        isQrOpen={isQrOpen}
        isAccountsOpen={isAccountsOpen}
        isAccountsExpanded={isAccountsExpanded}
        setIsAccountsOpen={setIsAccountsOpen}
        setIsQrOpen={setIsQrOpen}
        setAccountsExpanded={setAccountsExpanded}
      />
      <div className="flex-1 flex flex-col min-h-0">
        <DisplayCostLite
          setIsAccountsOpen={setIsAccountsOpen}
          setIsAccountsExpanded={setAccountsExpanded}
        />
        <div className="px-2 py-2 flex-1 flex flex-col min-h-0">
          <div className="flex mb-4">
            <button
              className={`px-3 py-2 rounded-full cursor-pointer z-10 ${
                activeTab === "crypto"
                  ? "text-white font-semibold"
                  : "text-white/40 font-normal"
              }`}
              onClick={() => handleTabChange("crypto")}
            >
              Cryptos
            </button>
            <button
              className={`px-3 py-2 rounded-full cursor-pointer z-10 ${
                activeTab === "nft"
                  ? "text-white font-semibold"
                  : "text-white/40 font-normal"
              }`}
              onClick={() => handleTabChange("nft")}
            >
              NFTs
            </button>
          </div>
          <div className="flex-1 overflow-hidden">
            {activeTab === "crypto" ? <CryptoLite /> : <NftLite />}
          </div>
        </div>
        <div className="mb-2 flex items-center justify-center w-full">
          <Opentria url="https://t.me/tria_telegram_bot" />
        </div>
      </div>
    </div>
  );
}

export default Lite;
