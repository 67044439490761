import React, { useState, useMemo } from "react";
import { QrGenerator } from "./QrGenerator";
import { ChainAddressData, NetworkItem, useToast } from "../../../../rx.core";
import { isEvmChain, isAaSupportedChain } from "@tria-sdk/core";

interface Props {
  item: string | undefined;
  activeChain: NetworkItem | undefined;
  networkItems: (ChainAddressData & {
    logo: string;
  })[];

  logo?: string;
  liteMode?: boolean;
  isAA?: boolean;
}

const QR: React.FC<Props> = ({
  item,
  activeChain,
  networkItems,
  logo,
  liteMode,
  isAA,
}) => {
  const [copied, setIsCopied] = useState(false);
  const { setToastData, openToastMessage, closeToastMessage } = useToast();

  const copyToClipboard = (text: string | undefined) => {
    if (text) {
      setToastData({ title: "Copied!", desc: text, status: "message" });
      openToastMessage();
      setTimeout(() => {
        closeToastMessage();
      }, 800);
      setIsCopied(false);
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setTimeout(() => setIsCopied(true), 200);
        })
        .catch((error) => {
          console.error("Failed to copy: ", error);
        });
    }
    setTimeout(() => setIsCopied(false), 2000);
  };

  const handleCopyClick = () => {
    copyToClipboard(item);
  };

  const displayedLogos = useMemo(() => {
    if (!activeChain || !isEvmChain(activeChain.chainName)) {
      return [];
    }
    const isActiveChainAA = isAaSupportedChain(activeChain.chainName);

    const filteredNetworkItems = networkItems.filter((network) => {
      const isNetworkEvm = isEvmChain(network.chainName);
      const isNetworkAa = isAaSupportedChain(network.chainName);
      return isActiveChainAA
        ? isNetworkAa && isNetworkEvm
        : !isNetworkAa && isNetworkEvm;
    });

    return filteredNetworkItems.slice(0, 9);
  }, [activeChain, networkItems]);

  return (
    <div
      className={`${
        liteMode ? "w-full" : "w-[40.5vh]"
      } flex-col justify-center items-center flex rounded-lg py-4 px-6`}
    >
      {activeChain?.chainName === "SOLANA" ? (
        <QrGenerator content={item} logo={logo} />
      ) : (
        <QrGenerator content={item} logo={logo} />
      )}
      {isEvmChain(activeChain?.chainName || "") &&
        displayedLogos.length > 0 && (
          <div className="flex gap-2 mt-4">
            {displayedLogos.map((network, index) => (
              <div key={index} className="">
                <img
                  src={network?.logo}
                  alt=""
                  width={18}
                  height={18}
                  className="rounded-sm"
                />
              </div>
            ))}
          </div>
        )}
      <div
        className={`${
          liteMode
            ? "dark:bg-popoverBackgroundDark "
            : "dark:bg-primaryDarkColor"
        } px-[2.4vh] pt-3 bg-primaryColor flex-col justify-start items-center gap-[1vh] inline-flex relative rounded-b-lg`}
      >
        <div className="self-stretch gap-2 flex flex-col justify-center items-center">
          <div className="grow shrink basis-0 flex-col justify-center items-center inline-flex">
            <div className="self-center justify-start items-center gap-[1vh] inline-flex">
              <div className="text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-sm font-semibold  leading-tight">
                <span>Your </span>
                <span>{activeChain?.chainName.toLowerCase()} </span>
                <span>address</span>
              </div>
            </div>
            <span className="text-center text-neutral-400 text-xs font-semibold overflow-hidden leading-tight break-words w-full max-w-[200px]">
              {item}
            </span>
          </div>
          <div className=" bg-hoverDarkColor dark:bg-white rounded-[2.9vh] justify-center items-center flex cursor-pointer">
            <div
              className="px-6 py-2 self-stretch justify-center items-center gap-[1vh] flex"
              onClick={handleCopyClick}
            >
              {!copied && (
                <div className="text-center text-black text-xs font-semibold  leading-tight">
                  copy
                </div>
              )}
              {copied && (
                <div className="text-center text-black text-xs font-semibold leading-tight">
                  copied
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QR;
