import { ethers } from "ethers";
import { waitForTransaction as waitForTxn } from "@tria-sdk/chains";
import { waitForTransaction as waitForAaTxn } from "@tria-sdk/aa";
import { CHAIN_ERROR, rpcUrls } from "@tria-sdk/constants";
import { Hex } from "viem";

export type ContractDetails = {
  contractAddress: string;
  abi: Object[];
  functionName: string;
  args: any[];
  value?: number;
  enableTriaName?: boolean;
};

export type ReadContractResponse = {
  success: boolean;
  data?: any;
  message?: string;
  error?: any;
};

export class ChainController {
  rpcUrl: string;
  chainName: string;

  constructor(chainName: string, rpcUrl?: string) {
    this.rpcUrl = rpcUrl || (rpcUrls[chainName] as string);
    this.chainName = chainName;
  }

  async readContract(
    contractDetails: ContractDetails
  ): Promise<ReadContractResponse> {
    try {
      const { contractAddress, abi, functionName, args } = contractDetails;
      const { contract } = await this.getContract(contractAddress, abi);

      // Invoke the contract function
      const data = await contract[functionName](...args);

      // Handle the result
      // console.log('readContract', data);
      return { success: true, data };
    } catch (error) {
      console.error(`Error in readContract: ${error}`);
      return { success: false, message: CHAIN_ERROR.READ_CONTRACT, error };
    }
  }

  async getContract(
    contractAddress: string,
    abi: Object[]
  ): Promise<{ contract: ethers.Contract }> {
    try {
      const contractAbi = abi;
      const contract = new ethers.Contract(
        contractAddress,
        contractAbi,
        this.getProvider()
      );
      return {
        contract,
      };
    } catch (err) {
      console.error(`Error in getContract: ${err}`);
      throw new Error(CHAIN_ERROR.GET_CONTRACT);
    }
  }

  getProvider(): ethers.providers.JsonRpcProvider {
    const providerInstance = new ethers.providers.JsonRpcProvider(this.rpcUrl);
    return providerInstance;
  }

  async waitForTransaction({
    txnHash,
    userOpHash,
    confirmations,
    timeout,
  }: {
    txnHash: string;
    userOpHash?: Hex;
    confirmations?: number | undefined;
    timeout?: number | undefined;
  }): Promise<any> {
    try {
      if (this.chainName == "SOLANA") {
        return await waitForTxn(txnHash, confirmations);
      }

      if (txnHash) {
        const txn = await this.getProvider().waitForTransaction(
          txnHash,
          confirmations,
          timeout
        );
        return txn;
      } else if (userOpHash) {
        const txnHash = await waitForAaTxn({
          userOperationHash: userOpHash,
          chainName: this.chainName,
        });
        return txnHash;
      }
    } catch (err) {
      throw err;
    }
  }
}
