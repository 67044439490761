import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  ChainAddressData,
  NetworkItem,
  RootState,
  copyToClipboard,
  formatAddress,
  useToast,
} from "../../../../rx.core";
import { CopyButton } from "../Buttons";
import { QrButton } from "../Buttons/QrButton";
import QR from "./QR";
import { chainNameToLogo } from "@tria-sdk/constants";

interface Props {
  userAddresses: ChainAddressData[];
  userNonEvmAddresses: ChainAddressData[];
  supportedChains: string[];
  priorityChains?: string[];
  lite?: boolean;
  qrHandler?: (value: boolean) => void;
  setAccountsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  accountsExpanded: boolean;
}

export const Accounts: React.FC<Props> = ({
  userAddresses,
  userNonEvmAddresses,
  supportedChains,
  priorityChains = ["ETH", "SOLANA", "BINANCE"],
  lite = false,
  qrHandler,
  accountsExpanded,
  setAccountsExpanded,
}) => {
  const [showQr, setShowQr] = useState(false);
  const [qrData, setQrData] = useState({
    address: "",
    chain: undefined as NetworkItem | undefined,
  });
  const [copied, setCopied] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showSearch, setShowSearch] = useState(false);

  const { setToastData, openToastMessage, closeToastMessage } = useToast();
  const networkItems = useSelector(
    (store: RootState) => store.Constants?.NetworkItems
  );

  const allChains = useMemo(() => {
    const combinedAddresses = [...userAddresses, ...userNonEvmAddresses];

    return supportedChains
      .map((chainName) => {
        const chainData = combinedAddresses.find(
          (addr) => addr.chainName.toUpperCase() === chainName.toUpperCase()
        );

        if (!chainData) return null;

        return {
          ...chainData,
          logo: chainNameToLogo[chainName] || "",
        };
      })
      .filter(Boolean) as (ChainAddressData & { logo: string })[];
  }, [supportedChains, userAddresses, userNonEvmAddresses]);

  const sortedChains = useMemo(() => {
    const priorityMap = new Map(
      priorityChains.map((chain, index) => [chain.toUpperCase(), index])
    );

    const prioritized = priorityChains
      .map((chainName) =>
        allChains.find(
          (chain) => chain.chainName.toUpperCase() === chainName.toUpperCase()
        )
      )
      .filter(Boolean) as (ChainAddressData & { logo: string })[];

    const rest = allChains.filter(
      (chain) => !priorityMap.has(chain.chainName.toUpperCase())
    );

    return [...prioritized, ...rest];
  }, [allChains, priorityChains]);

  const displayedChains = useMemo(() => {
    if (!accountsExpanded && !searchTerm) {
      return sortedChains.slice(0, priorityChains.length);
    }
    return sortedChains.filter((chain) =>
      chain.chainName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [sortedChains, accountsExpanded, searchTerm, priorityChains]);

  useEffect(() => {
    if (sortedChains.length > priorityChains.length) {
      setShowSearch(true);
    }
  }, [sortedChains, priorityChains]);

  const handleCopyClick = (address: string) => {
    const { copied, text } = copyToClipboard(address);
    setCopied(copied);
    if (copied) {
      setToastData({ title: "Copied!", desc: text, status: "message" });
      openToastMessage();
      setTimeout(() => {
        closeToastMessage();
        setCopied(false);
      }, 4000);
    }
  };

  const handleQrClick = (address: string, chain: NetworkItem | undefined) => {
    setShowQr(!showQr);
    setQrData({ address, chain });
    qrHandler && qrHandler(!showQr);
  };

  const renderChainItem = (chain: (typeof sortedChains)[0]) => (
    <div
      key={chain.id}
      className="self-stretch justify-start items-center gap-[2vh] inline-flex mb-4"
    >
      <div className="flex gap-[0.5vh] items-center">
        <img
          src={chain.logo}
          alt={chain.chainName}
          className="w-[2.8vh] h-[2.8vh] rounded-[0.6vh]"
        />
      </div>
      <div className="grow shrink basis-0 flex-col justify-start items-start gap-[0.5vh] inline-flex">
        <div className="flex items-center">
          <div
            className={`${
              lite ? "text-sm" : "text-[2vh]"
            } text-left text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 font-semibold leading-tight`}
          >
            {chain.chainName}
          </div>
          {chain.isAA && (
            <span className="px-1 py-0.5 rounded">
              <img src="/icons/smart-wallet.svg" className="w-4 h-4" />
            </span>
          )}
        </div>
        <div
          className={`${
            lite ? "text-xs" : "text-[1.67vh]"
          } text-center text-neutral-400 font-semibold leading-tight max-w-[24vh] overflow-hidden`}
        >
          {formatAddress(chain.address)}
        </div>
      </div>
      <QrButton
        onClick={() =>
          handleQrClick(
            chain.address,
            networkItems.find((item) => item.chainName === chain.chainName)
          )
        }
      />
      <CopyButton
        onClick={() => handleCopyClick(chain.address)}
        height="2.1vh"
        width="2.1vh"
      />
    </div>
  );
  return (
    <div
      className={`min-w-full bg-primaryColor ${
        showQr ? "transform duration-200" : "h-[unset]"
      } rounded-[2vh] ${
        lite
          ? "dark:bg-popoverBackgroundDark"
          : "dark:bg-primaryDarkColor max-h-[62.5vh]"
      }`}
    >
      {!showQr && showSearch && (
        <div className="px-4 pb-2 pt-4">
          <div className="text-sm text-white w-full text-center justify-center mb-4 font-semibold">
            All Networks
          </div>
          <input
            type="text"
            placeholder="Search"
            className="text-white text-sm w-full h-[4vh] rounded-full px-3 mb-4 bg-primaryColor dark:bg-hoverDarkColor focus:outline-none focus:border focus:border-neutral-600"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      )}

      {!showQr && (
        <>
          <div
            className={`max-h-[280px] overflow-y-scroll pl-4 pr-2 pt-4 flex-col justify-start gap-2 inline-flex min-w-full relative`}
          >
            {displayedChains.map(renderChainItem)}

            {copied && (
              <div className="absolute bg-primaryColor dark:bg-primaryDarkColor text-fontLightColor dark:text-fontLightColorDark flex items-center justify-center rounded-[25px] w-[8.3vh] h-[2.8vh] bottom-[-1.2vh] right-[1.2vh] text-[1.67vh]">
                Copied
              </div>
            )}
          </div>
          {sortedChains.length > priorityChains.length && !searchTerm && (
            <div
              className="text-sm w-full flex justify-center items-center px-2 pb-3 text-white/80 underline cursor-pointer"
              onClick={() => setAccountsExpanded(!accountsExpanded)}
            >
              {accountsExpanded ? "" : "Show more"}
            </div>
          )}
        </>
      )}
      {showQr && (
        <div
          className={`${
            lite ? "dark:bg-popoverBackgroundDark" : "dark:bg-primaryDarkColor"
          } shadow-2xl flex bg-primaryColor dark:bg-popoverBackgroundDark rounded-[2vh] z-50 ${
            showQr ? "" : "hidden"
          }`}
        >
          <QR
            item={qrData.address}
            activeChain={qrData.chain}
            networkItems={allChains}
            logo={qrData.chain?.logo || ""}
            liteMode={lite}
          />
        </div>
      )}
    </div>
  );
};

export default Accounts;
// import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import {
//   NetworkItem,
//   NonEvmAddressesType,
//   RootState,
//   copyToClipboard,
//   formatAddress,
//   useToast,
// } from "../../../../rx.core";
// import { CopyButton } from "../Buttons";
// import { QrButton } from "../Buttons/QrButton";
// import QR from "./QR";
// import { uniq } from "lodash";
// import { isEvmChain } from "@tria-sdk/core";

// interface Props {
//   addresses: string;
//   activeChain: NetworkItem | undefined;
//   nonEvmAddresses?: NonEvmAddressesType[];
//   lite?: boolean;
//   qrHandler?: (value: boolean) => void;
// }

// export const Accounts: React.FC<Props> = ({
//   addresses,
//   activeChain,
//   nonEvmAddresses,
//   lite = false,
//   qrHandler,
// }) => {
//   const [showQr, setShowQr] = useState(false);
//   const [qrAddress, setQrAddress] = useState("");
//   const [qrChain, setQrChain] = useState<NetworkItem>();
//   const [qrNetworkItems, setQrNetworkItems] = useState<NetworkItem[]>();
//   const [qrLogo, setQrLogo] = useState("");
//   const [copied, setIsCopied] = useState(false);
//   const [copiedText, setCopiedText] = useState("");
//   const { setToastData, openToastMessage, closeToastMessage } = useToast();
//   const [expanded, setExpanded] = useState(false);
//   const [searchTerm, setSearchTerm] = useState("");

//   const networkItems = useSelector(
//     (store: RootState) => store.Constants?.NetworkItems
//   );
//   const aaNetworkItems = useSelector(
//     (store: RootState) => store.Constants?.AANetworks
//   );
//   console.log("account : networkitems", aaNetworkItems);
//   console.log("account : aaNetworkitems", aaNetworkItems);

//   const allChains = [
//     {
//       // chainName: isEvmChain(activeChain?.chainName ?? "")
//       //   ? activeChain?.chainName
//       //   : "ETH",
//       chainName: isEvmChain(activeChain?.chainName ?? "") ? "ETH" : "ETH",
//       address: addresses,
//     },
//     ...(nonEvmAddresses || []),
//   ];

//   const filteredChains = allChains.filter((chain) =>
//     chain?.chainName?.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   const handleCopyClick = (item: string) => {
//     setIsCopied(copyToClipboard(item)?.copied);
//     setCopiedText(copyToClipboard(item)?.text || "");
//   };

//   useEffect(() => {
//     if (copied) {
//       setToastData({ title: "Copied!", desc: copiedText, status: "message" });
//       openToastMessage();
//       setIsCopied(false);
//       setTimeout(() => closeToastMessage(), 4000);
//     }
//   }, [copied]);

//   const handleQrClick = (
//     address: string,
//     qrChain?: NetworkItem,
//     qrItems?: NetworkItem[],
//     qrLogo?: string
//   ) => {
//     setShowQr(!showQr);
//     setQrAddress(address);
//     setQrChain(qrChain);
//     setQrNetworkItems(qrItems);
//     qrHandler && qrHandler(!showQr);
//     if (qrLogo) setQrLogo(qrLogo);
//   };

//   const toggleExpand = () => {
//     setExpanded(!expanded);
//   };

//   return (
//     <div
//       className={`min-w-full bg-primaryColor ${
//         showQr ? "transform duration-200" : "h-[unset]"
//       } rounded-[2vh] ${
//         lite
//           ? "dark:bg-popoverBackgroundDark"
//           : "dark:bg-primaryDarkColor h-[53.5vh]"
//       }`}
//     >
//       {!showQr && filteredChains.length > 3 && (
//         <div className="px-[2vh] py-[1.4vh]">
//           <div className="text-sm text-white w-full text-center justify-center mb-4 font-semibold">
//             All Networks
//           </div>
//           <input
//             type="text"
//             placeholder="Search"
//             className="text-white text-sm w-full h-[4vh] rounded-full px-3 mb-4 bg-primaryColor dark:bg-hoverDarkColor focus:outline-none focus:border focus:border-neutral-600"
//             value={searchTerm}
//             onChange={(e) => setSearchTerm(e.target.value)}
//           />
//         </div>
//       )}

//       {!showQr && (
//         <div
//           className={`overflow-y-scroll max-h-[280px] w-full px-[2.4vh] pt-[2.4vh] flex-col justify-start gap-[1vh] inline-flex min-w-full relative`}
//         >
//           {filteredChains
//             .slice(0, expanded ? filteredChains.length : 3)
//             .map((chain, index) => (
//               <div
//                 key={index}
//                 className="self-stretch justify-start items-center gap-[2vh] inline-flex mb-4"
//               >
//                 <div className="flex gap-[0.5vh] flex-wrap">
//                   {chain.chainName?.toUpperCase() === "AA"
//                     ? uniq(aaNetworkItems.map((el) => el)).map(
//                         (element, logoIndex) => {
//                           if (element.chainName === "BINANCE")
//                             return (
//                               <img
//                                 key={logoIndex}
//                                 src={element.logo}
//                                 alt=""
//                                 className="w-[2.8vh] h-[2.8vh] rounded-[0.6vh]"
//                               />
//                             );
//                         }
//                       )
//                     : // .slice(9, 10)
//                       // ?.map((item: string, logoIndex: number) => (

//                       //   <img
//                       //     key={logoIndex}
//                       //     src={item}
//                       //     alt=""
//                       //     className="w-[2.8vh] h-[2.8vh] rounded-[0.6vh]"
//                       //   />
//                       // ))
//                       networkItems?.find(
//                         (item) =>
//                           chain.chainName?.toUpperCase() ===
//                           item?.chainName?.toUpperCase()
//                       ) && (
//                         <img
//                           src={
//                             networkItems.find(
//                               (item) =>
//                                 chain.chainName?.toUpperCase() ===
//                                 item?.chainName?.toUpperCase()
//                             )?.logo
//                           }
//                           alt=""
//                           className="w-[2.8vh] h-[2.8vh] rounded-[0.6vh]"
//                         />
//                       )}
//                 </div>
//                 <div className="grow shrink basis-0 flex-col justify-start items-start gap-[0.5vh] inline-flex">
//                   <div className="self-stretch justify-start items-center gap-[1vh] inline-flex">
//                     <div
//                       className={`${
//                         lite ? "text-sm" : "text-[2vh]"
//                       } text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 font-semibold leading-tight`}
//                     >
//                       {chain.chainName === "aa" ? "BNB" : chain.chainName}
//                     </div>
//                   </div>
//                   <div
//                     className={`${
//                       lite ? "text-xs" : "text-[1.67vh]"
//                     } text-center text-neutral-400 font-semibold leading-tight max-w-[24vh] overflow-hidden`}
//                   >
//                     {formatAddress(chain.address)}
//                   </div>
//                 </div>
//                 <div
//                   className="w-[2.1vh] h-[2.1vh] justify-center items-center flex cursor-pointer"
//                   onClick={() =>
//                     handleQrClick(
//                       chain.address,
//                       networkItems.find(
//                         (item) => item.chainName === chain.chainName
//                       )
//                     )
//                   }
//                 >
//                   <QrButton />
//                 </div>
//                 <CopyButton
//                   onClick={() => handleCopyClick(chain.address)}
//                   height="2.1vh"
//                   width="2.1vh"
//                 />
//               </div>
//             ))}
//           {filteredChains.length > 3 && (
//             <div
//               className="text-sm w-full flex justify-center items-center px-[2vh] py-3 text-white/80 underline cursor-pointer"
//               onClick={toggleExpand}
//             >
//               {expanded ? "Show less" : "Show more"}
//             </div>
//           )}
//           {copied && (
//             <div className="absolute bg-primaryColor dark:bg-primaryDarkColor text-fontLightColor dark:text-fontLightColorDark flex items-center justify-center rounded-[25px] w-[8.3vh] h-[2.8vh] bottom-[-1.2vh] right-[1.2vh] text-[1.67vh]">
//               Copied
//             </div>
//           )}
//         </div>
//       )}
//       {showQr && (
//         <div
//           className={`${
//             lite ? "dark:bg-popoverBackgroundDark" : "dark:bg-primaryDarkColor"
//           } shadow-2xl  flex bg-primaryColor dark:bg-popoverBackgroundDark rounded-[2vh] z-50 ${
//             showQr ? "" : "hidden"
//           }`}
//         >
//           <QR
//             item={qrAddress}
//             activeChain={qrChain}
//             networkItems={qrNetworkItems || []}
//             logo={qrLogo}
//             liteMode={lite}
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default Accounts;
