import React, { useState, useEffect, useCallback } from "react";
import CheapSwapIcon from "../icons/CheapSwap";
import BalancedSwapIcon from "../icons/BalancedSwap";
import FastSwapIcon from "../icons/FastSwap";
import { useTriaUser } from "../../contexts";
import { SwapPreferences } from "@tria-sdk/core";
import { debounce } from "lodash";

const TransactionPreferenceSlider = () => {
  const [sliderValue, setSliderValue] = useState(50);
  const [activePreference, setActivePreference] = useState("balanced");
  const { updateSwapPreference } = useTriaUser();

  // Function to map slider value (0-100) to cost-time preference (0-1)
  const mapSliderToPreference = (value: number): number => {
    if (value < 33) {
      // Map 0-33 to 0-0.33 and then adjust to be closer to 0
      return 0;
    } else if (value > 66) {
      // Map 66-100 to 0.66-1 and then adjust to be closer to 1
      return 1;
    } else {
      // Center section should be close to 0.5
      return 0.5;
    }
  };

  // Create a debounced version of the preference update
  const debouncedUpdatePreference = useCallback(
    debounce(async (value: number) => {
      const costTimePreference = mapSliderToPreference(value);
      const preferences: SwapPreferences = {
        costTimePreference: costTimePreference,
      };
      await updateSwapPreference(preferences);
    }, 1000),
    [updateSwapPreference]
  );

  // Cleanup the debounced function on unmount
  useEffect(() => {
    return () => {
      debouncedUpdatePreference.cancel();
    };
  }, [debouncedUpdatePreference]);

  // Function to update both local state and trigger debounced update
  const updatePreference = useCallback(
    (value: number) => {
      setSliderValue(value);
      debouncedUpdatePreference(value);
    },
    [debouncedUpdatePreference]
  );

  useEffect(() => {
    if (sliderValue < 33) {
      setActivePreference("cheap");
    } else if (sliderValue > 66) {
      setActivePreference("fast");
    } else {
      setActivePreference("balanced");
    }
  }, [sliderValue]);

  // New function to handle icon clicks
  const handlePreferenceClick = (preference: string) => {
    let newValue: number;

    switch (preference) {
      case "cheap":
        newValue = 16; // Will map to ~0
        break;
      case "balanced":
        newValue = 50; // Will map to 0.5
        break;
      case "fast":
        newValue = 84; // Will map to ~1
        break;
      default:
        newValue = 50;
    }
    updatePreference(newValue);
  };

  const getSliderGradient = () => {
    const gradientWidth = 200; // Width of gradient in pixels

    switch (activePreference) {
      case "cheap":
        return {
          background: `linear-gradient(90deg, 
            #A0DE91 0%, 
            rgba(160, 222, 145, 0.3) 70%, 
            transparent 100%)`,
          width: `${gradientWidth}px`,
          left: "0%",
        };
      case "balanced":
        return {
          background: `linear-gradient(90deg, 
            transparent 0%,
            rgba(255, 192, 29, 0.3) 20%, 
            #FFC01D 50%, 
            rgba(255, 192, 29, 0.3) 80%, 
            transparent 100%)`,
          width: `${gradientWidth}px`,
          left: "50%",
          transform: "translateX(-50%)",
        };
      case "fast":
        return {
          background: `linear-gradient(270deg, 
            #2AD9FF 0%, 
            rgba(42, 217, 255, 0.3) 70%, 
            transparent 100%)`,
          width: `${gradientWidth}px`,
          right: "0%",
        };
      default:
        return {
          background: `linear-gradient(90deg, 
            transparent 0%,
            rgba(255, 192, 29, 0.3) 20%, 
            #FFC01D 50%, 
            rgba(255, 192, 29, 0.3) 80%, 
            transparent 100%)`,
          width: `${gradientWidth}px`,
          left: "50%",
          transform: "translateX(-50%)",
        };
    }
  };

  const getHandleColor = () => {
    switch (activePreference) {
      case "cheap":
        return "bg-[#A0DE91]";
      case "balanced":
        return "bg-[#FFC01D]";
      case "fast":
        return "bg-[#2AD9FF]";
      default:
        return "bg-[#FFC01D]";
    }
  };

  const getHandleShadow = () => {
    switch (activePreference) {
      case "cheap":
        return "0 0 10px rgba(160, 222, 145, 0.5)";
      case "balanced":
        return "0 0 10px rgba(255, 192, 29, 0.5)";
      case "fast":
        return "0 0 10px rgba(42, 217, 255, 0.5)";
      default:
        return "0 0 10px rgba(255, 192, 29, 0.5)";
    }
  };

  return (
    <div className="w-full px-4 mt-8">
      {/* Slider Container */}
      <div className="relative w-full h-1 bg-gray-700 rounded-full mb-6 overflow-hidden">
        <div
          className="absolute top-0 h-full rounded-full transition-all duration-200"
          style={{
            ...getSliderGradient(),
            zIndex: 1,
          }}
        />

        <div
          className={`absolute top-1/2 w-1.5 h-6 rounded-full cursor-pointer transition-all duration-200 ${getHandleColor()}`}
          style={{
            left: `${sliderValue}%`,
            transform: `translate(-50%, -50%)`,
            zIndex: 2,
            boxShadow: getHandleShadow(),
          }}
        />

        <input
          type="range"
          min="0"
          max="100"
          value={sliderValue}
          onChange={(e) => {
            setSliderValue(parseInt(e.target.value));
            updatePreference(parseInt(e.target.value));
          }}
          className="absolute top-0 w-full h-full opacity-0 cursor-pointer"
          style={{ zIndex: 3 }}
        />
      </div>

      {/* Icons and Labels */}
      <div className="flex justify-between items-start">
        {/* Cheap */}
        <div
          className="flex flex-col items-center cursor-pointer transition-opacity duration-200 hover:opacity-80"
          onClick={() => handlePreferenceClick("cheap")}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => e.key === "Enter" && handlePreferenceClick("cheap")}
        >
          <CheapSwapIcon
            width={40}
            height={40}
            primaryColor={activePreference === "cheap" ? "#A0DE91" : "#101010"}
            secondaryColor={
              activePreference === "cheap" ? "#1C530E" : "#101010"
            }
            strokeColor={activePreference === "cheap" ? "#FFFFFF" : "#FFFFFF"}
          />
          <span className="mt-2 text-sm text-white opacity-80">Cheap</span>
        </div>

        {/* Balanced */}
        <div
          className="flex flex-col items-center cursor-pointer transition-opacity duration-200 hover:opacity-80"
          onClick={() => handlePreferenceClick("balanced")}
          role="button"
          tabIndex={0}
          onKeyDown={(e) =>
            e.key === "Enter" && handlePreferenceClick("balanced")
          }
        >
          <BalancedSwapIcon
            width={40}
            height={40}
            primaryColor={
              activePreference === "balanced" ? "#FFC01D" : "#101010"
            }
            secondaryColor={
              activePreference === "balanced" ? "#C7B300" : "#101010"
            }
            strokeColor={
              activePreference === "balanced" ? "#FFFFFF" : "#FFFFFF"
            }
          />
          <span className="mt-2 text-sm text-center text-white opacity-80">
            Balanced
            <span className="block text-center opacity-60">(Recommended)</span>
          </span>
        </div>

        {/* Fast */}
        <div
          className="flex flex-col items-center cursor-pointer transition-opacity duration-200 hover:opacity-80"
          onClick={() => handlePreferenceClick("fast")}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => e.key === "Enter" && handlePreferenceClick("fast")}
        >
          <FastSwapIcon
            width={40}
            height={40}
            primaryColor={activePreference === "fast" ? "#2AD9FF" : "#101010"}
            secondaryColor={activePreference === "fast" ? "#00244E" : "#101010"}
            strokeColor={activePreference === "fast" ? "#FFFFFF" : "#FFFFFF"}
          />
          <span className="mt-2 text-sm text-white opacity-80">Fast</span>
        </div>
      </div>
    </div>
  );
};

export default TransactionPreferenceSlider;
