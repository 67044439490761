import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { ToastData } from "../../../types";
import { closeToast, openToast, setData } from "../../features";
import { useAppSelector } from "../base";

export function useToast() {
  const { isOpen, toastData } = useAppSelector((state) => state.toast);
  const dispatch = useDispatch();

  const setToastData = useCallback(
    (data: ToastData) => {
      dispatch(setData(data));
    },
    [dispatch]
  );

  const openToastMessage = useCallback(() => dispatch(openToast()), []);

  const closeToastMessage = useCallback(
    () => dispatch(closeToast()),
    [dispatch]
  );

  return {
    isOpen,
    toastData,
    setToastData,
    openToastMessage,
    closeToastMessage,
  };
}
