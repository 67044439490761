export const TRIA_THEMES = Object.freeze({
  TRIA_LIGHT: "tria-light",
  TRIA_DARK: "tria-dark",
});

export const THEME_PERSISTANCE_TYPES = Object.freeze({
  LOCAL_STORAGE: "local-storage",
  COOKIE: "cookie",
});

export const STORAGE_KEY: string = "theme";
export const COOKIE_MAX_AGE = 31536000;
export const COOKIE_MIN_AGE = 0;
