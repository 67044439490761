import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { GetAssetDetailsResponse, RootState } from "../package/rx.core";
import { useTriaUser } from "../package/ui.common/src/contexts/tria-user-provider/useTriaUser";
import { useUserPrefs } from "./useFetchUserPrefs";
import { useTriaName } from "./useTriaName";
import { CustomChainData } from "@tria-sdk/core";

export const useAssetDetails = (
  params?: Omit<
    UseQueryOptions<GetAssetDetailsResponse["data"]>,
    "queryKey" | "queryFn"
  >
) => {
  const { getAssetDetails } = useTriaUser();
  const { triaName } = useTriaName();
  const { chainName, tokenAddress, isCustomToken } = useSelector(
    (store: RootState) => store.Asset.fetchParam
  );
  const { data: userPrefs } = useUserPrefs();

  let chain: CustomChainData | undefined;
  if (isCustomToken) {
    chain = userPrefs?.customChains.find((c) => c?.chainName === chainName);
  }

  const enabledParams =
    !!triaName && (!isCustomToken || (isCustomToken && !!chain));

  const queryKey = ["assetDetails", triaName, chainName, tokenAddress];

  const query = useQuery<GetAssetDetailsResponse["data"]>({
    queryKey,
    queryFn: async () => {
      const accessToken = localStorage.getItem("tria.accessToken");
      const resp = await getAssetDetails({
        chainName,
        tokenAddress,
        chain,
        accessToken: accessToken || undefined,
      });
      if (resp instanceof Error) {
        throw new Error(resp.message);
      }
      const { success, data, message } = resp;
      if (!success) {
        const messageError =
          data && "message" in data ? (data.message as string) : message;
        throw new Error(messageError, { cause: "handled" });
      }
      return data ?? Promise.reject(new Error(message));
    },
    retry: (count, error) => count < 3 && error.cause !== "handled",
    staleTime: 5 * 60 * 1000, // 5 minutes
    enabled: (params?.enabled ?? true) && enabledParams,
    ...params,
  });

  return {
    ...query,
  };
};
