import React from "react";

const FastSwapIcon = ({
  width = 56,
  height = 57,
  primaryColor = "#2AD9FF",
  secondaryColor = "#00244E",
  glowColor = "#4EB5FF",
  strokeColor = "white",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <filter
          id="filter0_f_6139_13278"
          x="-1.4"
          y="-0.900001"
          width="58.8"
          height="58.8"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="7.2"
            result="effect1_foregroundBlur_6139_13278"
          />
        </filter>
        <filter
          id="filter1_i_6139_13278"
          x="12.9551"
          y="13.5"
          width="30"
          height="31.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.5" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_6139_13278"
          />
        </filter>
        <linearGradient
          id="paint0_linear_6139_13278"
          x1="27.9551"
          y1="13.5"
          x2="27.9551"
          y2="43.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={primaryColor} />
          <stop offset="0.856029" stopColor={secondaryColor} />
        </linearGradient>
      </defs>
      <g filter="url(#filter0_f_6139_13278)">
        <path
          d="M23.8497 29.4H26.0247V34.4625C26.0247 35.2125 26.9497 35.5625 27.4497 35L32.7747 28.95C33.2372 28.425 32.8622 27.6 32.1622 27.6H29.9872V22.5375C29.9872 21.7875 29.0622 21.4375 28.5622 22L23.2372 28.05C22.7747 28.575 23.1497 29.4 23.8497 29.4Z"
          stroke={glowColor}
          strokeWidth="1.875"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.9619 41C34.8655 41 40.4619 35.4036 40.4619 28.5C40.4619 21.5964 34.8655 16 27.9619 16C21.0584 16 15.4619 21.5964 15.4619 28.5C15.4619 35.4036 21.0584 41 27.9619 41Z"
          stroke={glowColor}
          strokeWidth="1.875"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g filter="url(#filter1_i_6139_13278)">
        <path
          d="M27.9551 43.5C36.2393 43.5 42.9551 36.7843 42.9551 28.5C42.9551 20.2157 36.2393 13.5 27.9551 13.5C19.6708 13.5 12.9551 20.2157 12.9551 28.5C12.9551 36.7843 19.6708 43.5 27.9551 43.5Z"
          fill="url(#paint0_linear_6139_13278)"
        />
      </g>
      <path
        d="M42.4301 28.5C42.4301 36.4943 35.9494 42.975 27.9551 42.975C19.9608 42.975 13.4801 36.4943 13.4801 28.5C13.4801 20.5057 19.9608 14.025 27.9551 14.025C35.9494 14.025 42.4301 20.5057 42.4301 28.5Z"
        stroke={strokeColor}
        strokeOpacity="0.4"
        strokeWidth="1.05"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.4886 29.6954H25.3768V36.4181C25.3768 37.414 26.6052 37.8788 27.2691 37.1318L34.3404 29.0978C34.9545 28.4006 34.4566 27.3051 33.527 27.3051H30.6388V20.5824C30.6388 19.5865 29.4104 19.1217 28.7465 19.8687L21.6752 27.9027C21.061 28.5998 21.559 29.6954 22.4886 29.6954Z"
        fill={strokeColor}
      />
    </svg>
  );
};

export default FastSwapIcon;
