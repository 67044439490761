import TransactionListHistory from "../../package/ui.common/src/layouts/Activity/TransactionListHistory";
import TransactionListUsers from "../../package/ui.common/src/layouts/Activity/TransactionListUsers";
import TransactionTabs from "../../package/ui.common/src/layouts/Activity/TransactionTabs";
import "../../index.css";

import { Footer, Header, Nav, UserContainer } from "../../package/ui.common";
import { useEffect, useState } from "react";
import {
  HistoryItem,
  RootState,
  updateCurrentRoute,
} from "../../package/rx.core";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFetchHistory } from "../../hooks/useFetchHistory";
import { useDispatch, useSelector } from "react-redux";
import { useFetchRecentUsers } from "../../hooks/useFetchRecentUsers";

function TransactionListPage() {
  const [activeTab, setActiveTab] = useState("history");
  const [selectedTransaction, setSelectedTransaction] = useState<
    HistoryItem | undefined
  >(undefined);
  const dispatch = useDispatch();
  // const params = useParams();

  dispatch(updateCurrentRoute(window.location.pathname));

  const userHistory = useSelector((state: RootState) => state.User.userHistory);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loading } = useFetchHistory();
  const historyItems = useSelector(
    (store: RootState) => store.User?.userHistory
  );
  useFetchRecentUsers();

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  const handleHistoryItemClick = (txHash: string) => {
    const selected = userHistory.find((item) => item.txnHash === txHash);
    if (selected) {
      setSelectedTransaction(selected);
      navigate("/activity/TransactionDetailCrypto", { state: { selected } });
    }
  };
  const recentUsers = useSelector(
    (store: RootState) => store.User.userRecentUsers
  );

  return (
    <>
      <div className="w-[53vh] h-[100vh] px-[1.4vh] flex items-center justify-center relative font-montserrat">
        <div className="w-[100%] h-[98%] py-[2vh] flex-col  items-start gap-[1.4vh] inline-flex rounded-[1.4vh]">
          <Nav />
          <Header />

          <TransactionTabs
            handleTabClick={handleTabClick}
            activeTab={activeTab}
          />

          {activeTab === "history" && (
            <>
              <div className="w-full flex flex-col justify-start items-center scrollbar-thin scrollbar-thumb-gray-300 h-full scrollbar-track-stone-100 ">
                <div className="pb-[1.4vh] grid grid-cols-2 gap-[1vh] px-[1vh]">
                  {recentUsers?.slice(0, 4)?.map((item) => (
                    <>
                      <UserContainer recentUser={item} />
                    </>
                  ))}
                </div>
                {historyItems.length > 0 && (
                  <TransactionListHistory
                    loading={loading}
                    items={historyItems}
                    onClick={handleHistoryItemClick}
                  />
                )}
              </div>
            </>
          )}
          {activeTab === "users" && <TransactionListUsers />}
          {/* {activeTab === 'recent' && <TransactionListRecent />} */}
        </div>
        <div className="absolute bottom-[2vh] visible nft-footer-bg-gradient dark:invisible dark:h-0 left-0 right-0 h-[11vh]">
          <Footer />
        </div>
        <div className="absolute bottom-[2vh] h-0 invisible dark:visible dark:h-[11vh] nft-footer-bg-gradient-dark left-0 right-0">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default TransactionListPage;
