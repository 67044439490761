interface Props {
  onClick: (event: React.MouseEvent) => void;
  height?: string;
  width?: string;
  color?: string;
}

export const CopyButton: React.FC<Props> = ({
  onClick: handleButtonClick,
  height = "2.4vh",
  width = "2.4vh",
  color,
}) => {
  return (
    <div className="group w-[3vh] h-[3vh] relative flex justify-center items-center">
      <div
        className={`rounded-full absolute flex cursor-pointer transform duration-300 opacity-100`}
        onClick={handleButtonClick}
        style={{ height: `${height}`, width: `${width}` }}
      >
        <img
          className="w-full h-full dark:w-0 dark:invisible visible"
          src="/icons/copy.svg"
          alt="Copy"
        />
        <img
          className="w-0 dark:w-full invisible dark:visible "
          src="/icons/copy-dark.svg"
          alt="Copy Dark"
        />
      </div>
      <div
        className={` absolute cursor-pointer transform duration-300 overflow-hidden opacity-0 `}
        onClick={handleButtonClick}
        style={{ height: `${height}`, width: `${width}` }}
      >
        <img
          className="w-[4vh] scale-[180%] dark:w-0 dark:invisible visible "
          src="/icons/copyanimatedlight.svg"
          alt="Copy"
        />
        <img
          className="w-0 scale-[180%] dark:w-[4vh] invisible dark:visible "
          src="/icons/copyanimated.svg"
          alt="Copy Dark"
        />
      </div>
    </div>
  );
};
