import {
  AA_SUPPORTED_EVM_CHAINS,
  ALLCHAINS,
  GAS_ABS_ENABLED_CHAINS,
  SUPPORTED_EVM_CHAINS,
  TESTNET_CHAINS,
} from "./chains";

export const prodApiUrl = "https://production.tria.so";

export const isEvmChain = (chainName: string) => {
  return SUPPORTED_EVM_CHAINS.includes(chainName) || chainName == "EVM";
};

export const isAaSupportedChain = (chainName: string) => {
  return AA_SUPPORTED_EVM_CHAINS.includes(chainName);
};

export const isChainSupported = (chainName: string) => {
  return ALLCHAINS.includes(chainName) || chainName == "EVM";
};

export const isTestnetChain = (chainName: string) => {
  return TESTNET_CHAINS.includes(chainName) || chainName == "EVM";
};

export const isGasAbsEnabled = (chainName: string) => {
  return GAS_ABS_ENABLED_CHAINS.includes(chainName);
};
