import { useEffect, useState } from "react";

export interface OpenTriaInterface {
  url?: string;
}

export const Opentria: React.FC<OpenTriaInterface> = ({
  url,
}: OpenTriaInterface) => {
  const [params, setParams] = useState<string>();

  useEffect(() => {
    const storedData = localStorage.getItem("tria.wallet.params");
    if (storedData !== null) {
      setParams(storedData);
    } else {
      console.error("Can't find params!");
    }
  }, []);
  const handleRedirect = () => {
    if (url) {
      window.open(url, "_blank")?.focus();
    } else {
      let redirect = `${window.location.origin}/${params}`;
      console.log(window.location.origin);
      //     {
      //       process.env.REACT_APP_ENVIRONMENT === 'dev' || 'staging'
      //         ? (url = `https://staging-tria-wallet.vercel.app
      // /${params}`)
      //         : (url = `https://wallet.tria.so/${params}`)
      //     }
      window.open(redirect, "_blank")?.focus();
    }
  };

  return (
    <div
      className={`self-stretch py-[1.4vh] rounded-[5.2vh] justify-center items-center inline-flex  ml-[-1vh] `}
    >
      <div className="justify-center items-center flex ">
        <div className="w-[2.4vh] h-[2.4vh] relative rounded-[0.6vh]" />
        <div
          className="justify-center items-center  gap-[1vh] flex group cursor-pointer"
          onClick={handleRedirect}
        >
          <img
            src="/tria-logo.png"
            alt="wallet"
            className="w-[1.67vh] h-[1.67vh] opacity-50 group-hover:opacity-100"
          />
          <div className="text-center flex items-center text-zinc-400 text-sm font-semibold font-montserrat leading-tight opacity-50 group-hover:opacity-100">
            Open Tria
          </div>
          <img
            src="/icons/arrow-up.svg"
            alt="link"
            className="w-[2.1vh] h-[2.1vh] ml-[-0.5vh] opacity-50 group-hover:opacity-100"
          />
          {/* <div className=' relative'>
            <div className='origin-top-left  absolute'>
              <img src='/icons/arrow-up.png' alt='wallet' />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
