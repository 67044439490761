import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "../../../../../rx.core";
import { SendBuyButtons } from "../../Buttons/SendBuyButtons";
import "../index.css";
import eos from "./eos-dcc.svg";
import stackos from "./stackos-dcc.svg";
import triagreen from "./tria-green.svg";
import triaOrange from "./tria-orange.svg";
import triaPurple from "./tria-purple.svg";
import triaRose from "./tria-rose.svg";

import { WalletIcon } from "../../Buttons/WalletIcon";
import { SwapIcon } from "../../Buttons/SwapIcon";
import { QRCodeIcon } from "../../Buttons/QrCodeIcon";
import { useTotalBalance } from "../../../../../../hooks";

interface DisplayCostLiteProps {
  setIsAccountsOpen: Dispatch<SetStateAction<boolean>>;
  setIsAccountsExpanded: Dispatch<SetStateAction<boolean>>;
}

export const DisplayCostLite: React.FC<DisplayCostLiteProps> = ({
  setIsAccountsOpen,
  setIsAccountsExpanded,
}) => {
  const navigate = useNavigate();
  const [, setGradientBackground] = useState<string>("");

  const { data, isLoading } = useTotalBalance();

  useEffect(() => {
    const theme = localStorage.getItem("tria.wallet.store.app");
    if (theme) {
      const themeObj = JSON.parse(theme);
      const colorFromStorage = themeObj?.primaryColor;
      switch (colorFromStorage) {
        case "#FFA800":
          setGradientBackground(eos);
          break;
        case "#AAFF00":
          setGradientBackground(stackos);
          break;
        case "#7D40FF":
          setGradientBackground(triaPurple);
          break;
        case "#D7FF01":
          setGradientBackground(triagreen);
          break;
        case "#FF249D":
          setGradientBackground(triaRose);
          break;
        default:
          setGradientBackground(triaOrange);
      }
    }
  }, []);

  const getChangeColor = (value: number) => {
    return value >= 0 ? "text-green-500" : "text-red-500";
  };

  const balanceChange = data?.balance || 0 - (data?.balance1dBefore || 0);

  return (
    <div className="flex flex-col w-full text-center justify-center">
      <div className="py-4">
        <div className="text-white text-3xl font-semibold">
          {isLoading && (
            <div className="flex w-full justify-center">
              <div className="loading w-5 h-5"></div>
            </div>
          )}
          {!isLoading && formatCurrency(data?.balance || 0, false)}
        </div>
        {!isLoading && (
          <div className="flex justify-center mb-2">
            <div className={`${getChangeColor(balanceChange)} text-xs mr-1`}>
              {!isLoading &&
                `$${balanceChange.toFixed(
                  2
                )}(${data?.percentChangeIn24hr.toFixed(2)}%)`}
            </div>{" "}
            <div className="text-xs text-white/40">Today</div>
          </div>
        )}
      </div>

      <div className="flex space-x-2 m-3">
        <SendBuyButtons
          onClick={() => navigate("/home/sendcrypto?lite=true")}
          content="Send"
          icon={<WalletIcon color={"#FFFFFF"} width="2.4vh" height="2.4vh" />}
          large={true}
        />
        <SendBuyButtons
          onClick={() => navigate("/swap?lite=true")}
          content="Convert"
          icon={<SwapIcon color={"#FFFFFF"} width="2.4vh" height="2.4vh" />}
          large={true}
        />
        <SendBuyButtons
          onClick={() => {
            setIsAccountsOpen(true);
            setIsAccountsExpanded(true);
          }}
          content="Receive"
          icon={<QRCodeIcon color={"#FFFFFF"} width="2.4vh" height="2.4vh" />}
          large={true}
        />
      </div>
    </div>
  );
};
