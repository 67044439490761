import { BridgeParams, GetBridgeQuoteResponse } from "@tria-sdk/utils";
import { chainNameToChainId } from "../../utils/constants";
import axios from "axios";

export const getBridgeQuote = async ({
  src,
  dest,
}: BridgeParams): Promise<GetBridgeQuoteResponse> => {
  try {
    // Tria config
    const tria = {
      enableEstimate: false,
      affiliateFeePercent: 0,
      prependOperatingExpenses: false,
    };

    let config = {
      method: "get",
      // maxBodyLength: Infinity,
      url: "https://api.dln.trade/v1.0/dln/order/quote",
      params: {
        // src
        srcChainId:
          src.chain?.customChain?.chainId ||
          chainNameToChainId[src.chain?.chainName as string],
        srcChainTokenIn: src.token.address,
        srcChainTokenInAmount: src.token.amountInUnits,
        senderAddress: src.senderAddress,
        srcChainOrderAuthorityAddress: src.senderAddress,

        // dest
        dstChainId: dest.chainId,
        dstChainTokenOut: dest.token.address,
        dstChainTokenOutAmount: dest.token.amountInUnits,
        dstChainTokenOutRecipient: dest.recipientAddress,
        dstChainOrderAuthorityAddress:
          dest.authorityAddress || dest.recipientAddress,
        externalCall: JSON.stringify(dest.externalCall),

        // Tria config
        affiliateFeePercent: tria.affiliateFeePercent,
        enableEstimate: tria.enableEstimate,
        prependOperatingExpenses: tria.prependOperatingExpenses,
      },
      headers: {
        accept: "application/json",
      },
    };

    const { data } = await axios.get(config.url, {
      params: config.params,
      // headers: config.headers,
    });

    console.log({ data });

    return { success: true, data };
  } catch (err: any) {
    console.error(err);
    return { success: false, message: err?.message };
  }
};
