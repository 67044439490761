import { SkipRouteResponse } from "./getSkipRoute";

export function calculateTotalEstimatedFeesInUSD(
    response: SkipRouteResponse
  ): number {
    try {
      let totalUsdFee = 0;
    
      // Sum up the fees from the estimated_fees array
      const estimatedFees = response.estimated_fees;
      for (const fee of estimatedFees) {
        totalUsdFee += parseFloat(fee.usd_amount);
      }
    
      // Sum up the usd_fee_amount from any operation in the operations array
      for (const operation of response.operations) {
        for (const key in operation) {
          if (operation[key] && typeof operation[key] === "object") {
            if (operation[key].usd_fee_amount) {
              totalUsdFee += parseFloat(operation[key].usd_fee_amount);
            }
          }
        }
      }
    
      return totalUsdFee;
    }catch(err){
      return 0.001;
    }
  }