interface Props {
  content: string;
  onClick: () => void;
  disabled?: boolean;
  icon: React.ReactNode;
  large?: boolean;
}

// const { REACT_APP_TRIA_CONFIG_PATH } = process.env as ProcessEnv;

export const SendBuyButtons: React.FC<Props> = ({
  onClick: handleButtonClick,
  content,
  disabled = false,
  large = false,
  icon,
}) => {
  if (large) {
    return (
      <button
        className={`rounded-xl shadow-cardShadow${
          disabled ? "cursor-not-allowed" : " cursor-pointer"
        } justify-center items-center flex flex-col z-20 py-3 px-2 w-full space-y-1 dark:bg-secondaryDarkColorLite/40`}
        disabled={disabled}
        onClick={handleButtonClick}
      >
        <div className="w-5 h-5">
          <div className="w-full">{icon}</div>
        </div>
        <div className="text-center text-xs font-semibold  leading-tight text-fontPrimaryDarkColor">
          {content}
        </div>
      </button>
    );
  }

  return (
    <button
      className={`grow shrink basis-0 h-[4.76vh] px-[1.4vh] py-[1vh] rounded-lg ${
        disabled
          ? "bg-[#1515154D] cursor-not-allowed"
          : "bg-[#1515154D] cursor-pointer hover:bg-[#15151580]"
      } justify-center items-center gap-[1.4vh] flex z-20`}
      disabled={disabled}
      onClick={handleButtonClick}
    >
      <div className="text-center text-[2vh] font-semibold  leading-tight text-fontPrimaryDarkColor ">
        {content}
      </div>
      <div className="w-[2.1vh] h-[2.1vh]">
        <div className="w-[2.1vh] h-[2.1vh]">
          <div className=" w-full ">{icon}</div>
        </div>
      </div>
    </button>
  );
};
