import { Route } from "@lifi/sdk";

// Function to sum all executionDuration values in the nested JSON
export function calculateExecTime(data: Route[]) {
  try {
    let totalDuration = 0;

    // Helper function to recursively search for `executionDuration`
    function findAndSumDuration(obj: any) {
      for (let key in obj) {
        if (key === "executionDuration") {
          totalDuration += obj[key];
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
          findAndSumDuration(obj[key]);
        }
      }
    }

    findAndSumDuration(data);
    return totalDuration;
  } catch (err) {
    console.error(err);
    return 12;
  }
}
