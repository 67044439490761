import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { AssetDetail, RootState } from "../package/rx.core";
import { useTriaUser } from "../package/ui.common/src/contexts/tria-user-provider/useTriaUser";
import { useUserPrefs } from "./useFetchUserPrefs";
import { useTriaName } from "./useTriaName";
import { CustomChainData, CustomTokenData } from "@tria-sdk/core";

// Helper function to validate chain object
const isValidChain = (chain: CustomChainData): boolean => {
  return (
    chain &&
    typeof chain === "object" &&
    typeof chain.chainId !== "undefined" &&
    chain.chainId !== null
  );
};

// Helper function to validate token object
const isValidToken = (token: CustomTokenData): boolean => {
  return (
    token &&
    typeof token === "object" &&
    typeof token.chainId !== "undefined" &&
    typeof token.tokenAddress !== "undefined" &&
    token.chainId !== null &&
    token.tokenAddress !== null
  );
};

// Type for custom token with required isCustomToken
type AssetDetailWithIsCustomToken = AssetDetail & {
  isCustomToken: true;
};

export const useFetchAssets = (config?: { enabled?: boolean }) => {
  const { enabled } = config || { enabled: true };

  const queryClient = useQueryClient();
  const { getAssetsForATriaName, getAssetDetails } = useTriaUser();
  const { triaName } = useTriaName();
  const { data: userPrefs } = useUserPrefs();
  const isUserInactive = useSelector(
    (state: RootState) => state.User.isUserInactive
  );

  // Create the query function separately so we can reference it in prefetchAssets
  const fetchAllAssets = useCallback(async () => {
    if (!triaName) {
      return [];
    }

    const validChains = (userPrefs?.customChains || []).filter(isValidChain);
    const validTokens = (userPrefs?.customTokens || []).filter(isValidToken);
    const accessToken = localStorage.getItem("tria.accessToken");

    // Fetch both normal and custom tokens in parallel
    const [normalAssets, customTokens] = await Promise.all([
      getAssetsForATriaName(triaName, undefined, "amountDesc"),
      Promise.all(
        validChains.flatMap((chain) =>
          validTokens
            .filter((token) => token.chainId === chain.chainId)
            .map(async (token) => {
              try {
                const detail = await getAssetDetails({
                  tokenAddress: token.tokenAddress,
                  chain,
                  triaName,
                  type: "EOA",
                  accessToken: accessToken || undefined,
                });

                // Ensure we return null or a properly typed custom asset
                if (!detail.data) return null;

                return {
                  ...detail.data,
                  isCustomToken: true as const,
                };
              } catch (error) {
                console.warn(
                  `Failed to fetch details for token ${token.tokenAddress} on chain ${chain.chainId}:`,
                  error
                );
                return null;
              }
            })
        )
      ).then((results) =>
        results.filter(
          (result): result is AssetDetailWithIsCustomToken => result !== null
        )
      ),
    ]);

    // Combine and sort assets by balance
    const allAssets = [...normalAssets, ...customTokens].sort(
      (a, b) => (b?.balanceInUSD || 0) - (a?.balanceInUSD || 0)
    );

    return allAssets;
  }, [
    triaName,
    userPrefs?.customChains,
    userPrefs?.customTokens,
    getAssetsForATriaName,
    getAssetDetails,
  ]);

  const queryKeys = useMemo(() => {
    const baseKeys = ["all-assets", triaName];
    const chainKeys = (userPrefs?.customChains || []).map((chain) =>
      chain.chainId.toString()
    );
    const tokenKeys = (userPrefs?.customTokens || [])
      .filter((token) => token.tokenAddress)
      .map((token) => token.tokenAddress);

    return [...baseKeys, ...chainKeys, ...tokenKeys];
  }, [triaName, userPrefs?.customChains, userPrefs?.customTokens]);

  // Single query for both normal and custom tokens
  const assetsQuery = useQuery({
    queryKey: queryKeys,
    queryFn: fetchAllAssets,
    enabled: !!triaName && !isUserInactive && enabled,
    refetchInterval: 10000,
    refetchIntervalInBackground: true,
    retry: 3,
    staleTime: 5 * 60 * 1000,
  });

  // Prefetch function
  const prefetchAssets = useCallback(async () => {
    if (triaName) {
      await queryClient.prefetchQuery({
        queryKey: queryKeys,
        queryFn: fetchAllAssets,
        staleTime: 5 * 60 * 1000,
      });
    }
  }, [fetchAllAssets, queryClient, queryKeys, triaName]);

  // Memoized assets
  const allAssets = useMemo(() => assetsQuery.data || [], [assetsQuery.data]);

  return {
    allAssets,
    isLoading: assetsQuery.isLoading,
    isError: assetsQuery.isError,
    error: assetsQuery.error,
    refetch: () => assetsQuery.refetch(),
    prefetchAssets,
  };
};
