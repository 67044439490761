import React, { useEffect, useState } from "react";
import {
  formatNumberDisplay,
  formatAddress,
  copyToClipboard,
  useToast,
} from "../../../../../../rx.core";
import { UserAvatar } from "../../user/UserAvatar";
import { useResolveTriaName } from "../../../../../../../hooks";
import { AvatarContainer } from "../../user/AvatarContainer";
import { CopyButton } from "../../../Buttons";
import { type AvatarItem, type HistoryItem } from "../../../../../../rx.core";

type Props = {
  transaction: HistoryItem;
};

export const CryptoTransactionDetails: React.FC<Props> = ({ transaction }) => {
  const [triaName, setTriaName] = useState<string>();
  const [avatar, setAvatar] = useState<AvatarItem>();
  const [isCopied, setIsCopied] = useState<{
    status: boolean;
    value: string;
  }>();
  const getTriaName = useResolveTriaName();
  const { openToastMessage, setToastData, closeToastMessage } = useToast();

  useEffect(() => {
    const resolveName = async () => {
      if (transaction?.desc?.action === "Sent") {
        try {
          const response = await getTriaName(
            transaction?.chainName,
            transaction?.to?.address
          );
          setTriaName(response);
        } catch (err) {
          console.log(err);
        }
      } else if (transaction?.desc?.action === "Received") {
        try {
          const response = await getTriaName(
            transaction?.chainName,
            transaction?.from?.address
          );
          setTriaName(response);
        } catch (err) {
          console.log(err);
        }
      }
    };
    resolveName();
  }, [getTriaName, transaction]);
  useEffect(() => {
    const fetchAvatar = async () => {
      if (triaName) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_SDK_BASE_URL}/api/v1/user/getAvatarByTriaName?triaNames=${triaName}`
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          if (
            data &&
            data.response &&
            data.response[triaName] &&
            data.response[triaName]?.length > 0
          ) {
            const userData = data.response[triaName][0];
            if (userData?.avatar && userData?.background) {
              setAvatar({
                avatar: userData?.avatar,
                background: userData?.background,
              });
            }
          }
        } catch (err) {
          console.log(err);
        }
      }
    };
    fetchAvatar();
  }, [triaName]);

  const handleAddressCopy = (item: string) => {
    setIsCopied({ status: copyToClipboard(item)?.copied, value: item });
  };
  useEffect(() => {
    if (isCopied?.status) {
      setToastData({
        status: "message",
        title: "Copied!",
        desc: isCopied?.value,
      });
      openToastMessage();
      setTimeout(closeToastMessage, 4000);
    }
  }, [closeToastMessage, isCopied, openToastMessage, setToastData]);
  return (
    <div className="self-stretch h-[25.5vh] px-[2.4vh] py-[2vh] bg-primaryColor dark:bg-primaryDarkColor rounded-[2vh] border-[0.2vh] border-violet-400 border-opacity-30 flex-col justify-center items-center flex">
      <div className="h-[9.3vh] py-[1.4vh] flex-col justify-center items-start gap-[0.5vh] flex">
        <div className="self-stretch justify-center items-center gap-[1vh] inline-flex">
          <div className="text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-90 text-[3.1vh] font-semibold font-Montserrat leading-loose">
            ${transaction?.desc?.valueInUsd?.toFixed(6)}
          </div>
        </div>
        <div className="self-stretch justify-center items-center gap-[1vh] inline-flex">
          <div className="w-[2.85vh] h-[2.85vh]  flex justify-center items-center rounded-[0.5vh] overflow-hidden">
            <img
              alt="copy"
              className="w-[2.85vh] h-[2.85vh] ro"
              src={transaction?.chainLogo}
            />
          </div>
          <div className="text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-60 text-[2vh] font-medium font-Montserrat leading-tight">
            {formatNumberDisplay(
              transaction.desc.value
                ? parseFloat(transaction?.desc.value) /
                    10 ** transaction?.desc.decimals
                : 0,
              6
            )}{" "}
            <span>{transaction?.desc?.assetSymbol}</span>
          </div>
        </div>
      </div>
      <div className="w-[44.7vh] justify-center items-start gap-[2vh] inline-flex">
        <div className="grow shrink basis-0 py-[1.4vh] flex-col justify-center items-center gap-[1.4vh] inline-flex">
          {transaction?.desc?.action === "Sent" ? (
            <UserAvatar width="6vh" height="6vh" rounded="3vh" />
          ) : (
            <>
              {!avatar ? (
                <img
                  alt="user"
                  className="w-[6vh] h-[6vh] rounded-[6vh]"
                  src="/icons/user2.svg"
                />
              ) : (
                <AvatarContainer
                  avatar={avatar?.avatar}
                  background={avatar?.background}
                  height="6vh"
                  width="6vh"
                  borderRadius="3vh"
                />
              )}
            </>
          )}
          <div className="px-[1vh] justify-start items-center gap-[0.5vh] inline-flex">
            <div className="text-center text-fontLightColor dark:text-fontLightColorDark text-[1.4vh] font-semibold font-Montserrat leading-tight">
              {transaction?.desc?.action === "Sent" ? (
                <div className="px-[1vh] justify-start items-center flex">
                  <div className="text-center text-fontLightColor dark:text-fontLightColorDark text-[1.67vh] font-semibold  leading-tight flex flex-col">
                    <p className="flex items-center justify-center gap-[0.5vh]">
                      {" "}
                      You
                      {transaction?.wallet?.type === "AA" && (
                        <img src="/images/aaverifiedbatch.svg" alt="veri" />
                      )}
                    </p>
                    <p>({formatAddress(transaction?.wallet?.address)})</p>
                  </div>
                </div>
              ) : (
                <div className="px-[1vh] justify-start items-center flex">
                  <div className="text-center text-fontLightColor dark:text-fontLightColorDark text-[1.67vh] font-semibold  leading-tight">
                    {" "}
                    {!triaName
                      ? formatAddress(transaction?.from?.address)
                      : triaName}
                  </div>
                </div>
              )}
            </div>
            <div className="w-[2.1vh] h-[2.1vh] relative">
              <div className="w-[2.1vh] h-[2.1vh] left-0 top-0 absolute">
                <CopyButton
                  onClick={() => {
                    handleAddressCopy(transaction?.from?.address);
                  }}
                  width="2.1vh"
                  height="2.1vh"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="py-[2.85vh] flex-col justify-center items-center gap-[1.4vh] inline-flex">
          <img alt="arrow" src="/icons/arrow-right.svg"></img>
          <div className="w-[2.85vh] h-[2.85vh] relative">
            <div className="w-[2.85vh] h-[2.85vh] left-0 top-0 absolute"></div>
          </div>
        </div>
        <div className="grow shrink basis-0 py-[1.4vh] flex-col justify-center items-center gap-[1.4vh] inline-flex">
          {transaction?.desc?.action === "Sent" ? (
            <>
              {!avatar ? (
                <img
                  alt="user"
                  className="w-[6vh] h-[6vh] rounded-[6vh]"
                  src="/icons/user2.svg"
                />
              ) : (
                <AvatarContainer
                  avatar={avatar?.avatar}
                  background={avatar?.background}
                  height="6vh"
                  width="6vh"
                  borderRadius="3vh"
                />
              )}
            </>
          ) : (
            <UserAvatar width="6vh" height="6vh" rounded="3vh" />
          )}
          <div className="px-[1vh] justify-start items-center gap-[0.5vh] inline-flex">
            <div className="text-center text-fontLightColor dark:text-fontLightColorDark text-[1.67vh] font-semibold font-Montserrat leading-tight">
              {transaction?.desc?.action === "Sent" ? (
                <div className="text-center text-fontLightColor dark:text-fontLightColorDark text-[1.67vh] font-semibold  leading-tight">
                  {" "}
                  {!triaName
                    ? formatAddress(transaction?.to?.address)
                    : triaName}
                </div>
              ) : (
                <div className="text-center text-fontLightColor dark:text-fontLightColorDark text-[1.67vh] font-semibold  leading-tight flex flex-col">
                  <p className="flex items-center justify-center gap-[0.5vh]">
                    {" "}
                    You
                    {transaction?.wallet?.type === "AA" && (
                      <img src="/images/aaverifiedbatch.svg" alt="veri" />
                    )}
                  </p>
                  <p>({formatAddress(transaction?.wallet?.address)})</p>
                </div>
              )}
            </div>
            <div className="w-[2.1vh] h-[2.1vh] relative">
              <CopyButton
                onClick={() => {
                  handleAddressCopy(transaction?.to?.address);
                }}
                width="2.1vh"
                height="2.1vh"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
