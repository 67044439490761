import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./index.css";
import "./polyfills";
import reportWebVitals from "./reportWebVitals";

// import '@rainbow-me/rainbowkit/styles.css';
// import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { persistor } from "./package/rx.core";
import { webappLocalizationResources } from "./package/rx.i18n";
import { TriaProvider } from "./package/ui.common";
import { ReloadProvider } from "./package/ui.common/src/contexts/reload/ReloadContext";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, staleTime: 5 * 60 * 1000 },
  },
});

// const { chains, publicClient, webSocketPublicClient } = configureChains(
//   [
//     mainnet,
//     polygon,
//     optimism,
//     arbitrum,
//     base,
//     zora,
//     ...(process.env.REACT_APP_ENABLE_TESTNETS === "true" ? [goerli] : []),
//   ],
//   [publicProvider()]
// );

// const { connectors } = getDefaultWallets({
//   appName: 'RainbowKit demo',
//   projectId: 'YOUR_PROJECT_ID',
//   chains,
// });

// const wagmiConfig = createConfig({
//   autoConnect: true,
//   connectors,
//   publicClient,
//   webSocketPublicClient,
// });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  // dev-lit
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <TriaProvider appLocalizationResource={webappLocalizationResources}>
        <PersistGate loading={null} persistor={persistor}>
          {/* <WagmiConfig config={wagmiConfig}> */}
          {/* <RainbowKitProvider chains={chains}> */}
          <ReloadProvider>
            <App />
            <ReactQueryDevtools initialIsOpen={false} />
          </ReloadProvider>
          {/* </RainbowKitProvider> */}
          {/* </WagmiConfig> */}
        </PersistGate>
      </TriaProvider>
    </QueryClientProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
