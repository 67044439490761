import React from "react";

const CheapSwapIcon = ({
  width = 52,
  height = 52,
  primaryColor = "#A0DE91",
  secondaryColor = "#1C530E",
  strokeColor = "white",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <filter
          id="filter0_f_6139_13233"
          x="-1"
          y="-1"
          width="54"
          height="54"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="6"
            result="effect1_foregroundBlur_6139_13233"
          />
        </filter>
        <filter
          id="filter1_i_6139_13233"
          x="11"
          y="11"
          width="30"
          height="31"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_6139_13233"
          />
        </filter>
        <linearGradient
          id="paint0_linear_6139_13233"
          x1="26"
          y1="11"
          x2="26"
          y2="41"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={primaryColor} />
          <stop offset="0.846695" stopColor={secondaryColor} />
        </linearGradient>
      </defs>
      <g filter="url(#filter0_f_6139_13233)">
        <path
          d="M21.8398 28.9123C21.8398 30.5248 23.0773 31.8248 24.6148 31.8248H27.7523C29.0898 31.8248 30.1773 30.6873 30.1773 29.2873C30.1773 27.7623 29.5148 27.2248 28.5273 26.8748L23.4898 25.1248C22.5023 24.7748 21.8398 24.2373 21.8398 22.7123C21.8398 21.3123 22.9273 20.1748 24.2648 20.1748H27.4023C28.9398 20.1748 30.1773 21.4748 30.1773 23.0873"
          stroke={primaryColor}
          strokeWidth="1.875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26 18.5V33.5"
          stroke={primaryColor}
          strokeWidth="1.875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26 38.5C32.9036 38.5 38.5 32.9036 38.5 26C38.5 19.0964 32.9036 13.5 26 13.5C19.0964 13.5 13.5 19.0964 13.5 26C13.5 32.9036 19.0964 38.5 26 38.5Z"
          stroke={primaryColor}
          strokeWidth="1.875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g filter="url(#filter1_i_6139_13233)">
        <path
          d="M26 41C34.2843 41 41 34.2843 41 26C41 17.7157 34.2843 11 26 11C17.7157 11 11 17.7157 11 26C11 34.2843 17.7157 41 26 41Z"
          fill="url(#paint0_linear_6139_13233)"
        />
      </g>
      <path
        d="M40.475 26C40.475 33.9943 33.9943 40.475 26 40.475C18.0057 40.475 11.525 33.9943 11.525 26C11.525 18.0057 18.0057 11.525 26 11.525C33.9943 11.525 40.475 18.0057 40.475 26Z"
        stroke={strokeColor}
        strokeOpacity="0.4"
        strokeWidth="1.05"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0078 29.4948C21.0078 31.4298 22.4928 32.9898 24.3378 32.9898H28.1028C29.7078 32.9898 31.0128 31.6248 31.0128 29.9448C31.0128 28.1148 30.2178 27.4698 29.0328 27.0498L22.9878 24.9498C21.8028 24.5298 21.0078 23.8848 21.0078 22.0548C21.0078 20.3748 22.3128 19.0098 23.9178 19.0098H27.6828C29.5278 19.0098 31.0128 20.5698 31.0128 22.5048"
        stroke={strokeColor}
        strokeWidth="2.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26 17V35"
        stroke={strokeColor}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheapSwapIcon;
