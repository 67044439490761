import { useEffect, useState } from "react";
import { useFetchPopularCrypto } from "../../../../../../hooks";
import { PopularTokenItem } from "../../../../../rx.core";
import { SearchCryptoLayout } from "../../../layouts/Search/SearchCryptoLayout";
import { SearchedTokenContainer } from "./SearchedTokenContainer";

export const BuyCryptoSearch: React.FC = () => {
  const { popularToken } = useFetchPopularCrypto();
  const [showingResult, setShowingResult] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentAddress, setCurrentAddress] = useState<string>("");

  const handleShowingResultChange = (value: boolean) => {
    setShowingResult(value);
  };

  useEffect(() => {
    const storedData = localStorage.getItem("tria.wallet.store.app");
    if (storedData !== null) {
      const parsedData = JSON.parse(storedData);
      setCurrentAddress(parsedData?.addresses?.evm?.address);
    } else {
      console.log("Can't find triaName;");
    }
  }, []);

  const handleBuyToken = async (
    token: PopularTokenItem,
    currentAddress: string
  ) => {
    console.log(currentAddress);
    if (currentAddress) {
      try {
        setIsLoading(true);

        const url = `https://global.transak.com/?apiKey=83565d1e-05b6-415a-940c-b02baedd55c8&walletAddress=${currentAddress}&cryptoCurrencyCode=${token?.tokenName}&network=${token?.chainName}`;
        window.open(url, "_blank")?.focus();
      } catch (error: any) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="self-stretch rounded-[2vh] flex-col justify-center items-center gap-[0.1vh] flex ">
      <div className="w-[50vh] overflow-x-hidden overflow-y-auto">
        <SearchCryptoLayout onShowingResultChange={handleShowingResultChange} />
      </div>
      {!showingResult && (
        <>
          <div className="self-stretch px-[2.4vh] py-[1vh] justify-start items-center gap-[2vh] inline-flex">
            <div className="grow shrink basis-0 h-[1.67vh] justify-start items-center gap-[1vh] flex">
              <div className="text-center text-fontLightColor dark:text-fontLightColorDark text-opacity-40 text-[1.67vh] font-bold  uppercase leading-tight tracking-tight">
                Popular
              </div>
            </div>
          </div>
          <div className="self-stretch w-[49vh] h-[28.6vh] flex-col justify-start items-center gap-[1.4vh] flex">
            <div className="self-stretch max-h-[47.6vh] justify-start items-center gap-[1.4vh] grid grid-cols-2">
              {popularToken?.map(
                (token) =>
                  token.tokenName != "SOL" && (
                    <div className="w-[23.8vh]">
                      <SearchedTokenContainer
                        token={token}
                        onClick={() => handleBuyToken(token, currentAddress)}
                      />
                    </div>
                  )
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
