export const CHAIN_ERROR = {
  FEE_ESTIMATION: "Error estimating fee.",
  INSUFFICIENT_BALANCE: "Insufficient balance.",
  SEND_TOKEN: "Error creating send token txn.",
  CHECK_ALLOWANCE: "Error in checking token allowance.",
  BROADCAST_TXN: "Error in broadcasting transaction.",
  APPROVE_TXN: "Error in approving token.",
  GET_CONTRACT: "Error making contract instance.",
  READ_CONTRACT: "Error in reading contract data.",
  CALL_CONTRACT: "Error in creating contract transaction.",
  SEND_NFT: "Error in creating send NFT txn.",
  ENCRYPT: "Error in encryption.",
  DECRYPT: "Error in decryption.",
  SIGN_MESSAGE: "Error in signing message.",
};

export const DATA_VALIDATION = {
  INVALID_AMOUNT: "Invalid amount.",
  INVALID_TOKEN_ADDRESS: "Invalid tokenAddress.",
  NFT_TYPE: "Invalid NFT type.",
  CHAIN_NOT_SUPPORTED: "Chain not supported.",
};
