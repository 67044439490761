import {
  Names,
  RootState,
  updateCurrentRecipient,
  updateCurrentRecipientAddress,
  updateRecipientAvatar,
} from "../../../../../rx.core";
import { AvatarContainer } from "./AvatarContainer";
import { UserAvatar } from "./UserAvatar";
import { formatAddress } from "../../../../../rx.core";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

interface Props {
  user: Names;
  liteMode?: boolean;
}

export const SearchedUserContainer: React.FC<Props> = ({ user, liteMode }) => {
  const [randomColor, setRandomColor] = useState<string>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const prevPath = useSelector(
    (store: RootState) => store.Route?.previousRoute
  );

  const handleUserClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault();
    handleUserContainerClick();
  };

  const handleUserContainerClick = () => {
    if (user?.triaName) {
      dispatch(updateCurrentRecipient(user?.triaName));
      dispatch(
        updateRecipientAvatar({
          avatar: user?.avatarUrl || "",
          background: user?.background || "",
        })
      );
    } else if (user?.address) {
      dispatch(
        updateRecipientAvatar({
          char: user?.address?.charAt(user?.address?.length - 1),
          background: randomColor || "",
        })
      );
      dispatch(updateCurrentRecipient(""));
      dispatch(updateCurrentRecipientAddress(user?.address));
    }
    if (prevPath === "/assetHistory") {
      navigate("/home/sendCrypto/cryptoSendPage");
    } else if (prevPath === "/nfts/oneNft") {
      navigate("/home/sendCrypto/nftsend");
    } else {
      if (liteMode) {
        navigate("/home/sendCrypto/conversations?lite=true");
      } else {
        navigate("/home/sendCrypto/conversations");
      }
    }
  };

  useEffect(() => {
    setRandomColor(
      `${"#" + (((1 << 24) * Math.random()) | 0).toString(16).padStart(6, "0")}`
    );
  }, []);

  return (
    <div
      className="flex w-full h-[7.14vh] items-center gap-[1.4vh] flex-01 overflow-auto cursor-pointer"
      onClick={handleUserClick}
    >
      {user?.avatarUrl && (
        <AvatarContainer
          avatar={user?.avatarUrl}
          background={user?.background}
        />
      )}
      {!user?.avatarUrl && !user?.triaName && (
        <AvatarContainer
          char={user?.address?.charAt(user?.address?.length - 1)}
          background={randomColor}
        />
      )}
      {!user?.avatarUrl && !user?.address && (
        <AvatarContainer
          char={user?.triaName?.charAt(0)}
          background={randomColor}
        />
      )}
      {!user?.avatarUrl && user?.address && user?.triaName && (
        <AvatarContainer
          char={user?.triaName?.charAt(0)}
          background={randomColor}
        />
      )}
      <div className="flex w-[38.6vh] items-center gap-[1vh] flex-shrink-0 self-stretch text-center text-[2vh] font-[600] leading-tight text-fontPrimaryColor dark:text-fontPrimaryDarkColor">
        {user?.triaName || formatAddress(user?.address || "")}
      </div>
    </div>
  );
};
