import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFetchPopularCrypto } from "../../hooks";
import { updateCurrentRoute } from "../../package/rx.core";
import { BuyCryptoSearch, Footer, Nav } from "../../package/ui.common";
import { BackButton } from "../../package/ui.common/src/components/Buttons/BackButton";

interface CoinData {
  name: string;
  symbol: string;
  imageSrc: string;
}

const data: CoinData[] = [
  {
    name: "BITCOIN",
    symbol: "ETH",
    imageSrc: "/icons/tether.png",
  },
  {
    name: "BITCOIN",
    symbol: "ETH",
    imageSrc: "/icons/tether.png",
  },
  {
    name: "BITCOIN",
    symbol: "ETH",
    imageSrc: "/icons/tether.png",
  },
  {
    name: "BITCOIN",
    symbol: "ETH",
    imageSrc: "/icons/tether.png",
  },
  {
    name: "BITCOIN",
    symbol: "ETH",
    imageSrc: "/icons/tether.png",
  },
  {
    name: "BITCOIN",
    symbol: "ETH",
    imageSrc: "/icons/tether.png",
  },
  // Add more data objects for other items
];

export const BuyCrypto: React.FC = () => {
  const [input, setInput] = useState("");
  const { popularToken, loading } = useFetchPopularCrypto();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  dispatch(updateCurrentRoute(window.location.pathname));

  const HandleClick = () => {
    navigate("/home");
  };
  return (
    <>
      <div className="flex w-[53vh] h-[100vh] px-[1.4vh] items-center justify-center relative ">
        <div className="w-[100%] h-[98%] py-[2vh]  flex-col justify-start items-center gap-[1.4vh] inline-flex rounded-[1.4vh]  relative">
          <Nav />

          <div className="flex self-stretch w-[65%] items-center justify-start px-[1.67vh] gap-[1vh]">
            <BackButton onClick={HandleClick} />
            <p className="text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[2.4vh] font-[600]">
              Buy Crypto
            </p>
          </div>
          <div className="min-h-[60.5vh]">
            <BuyCryptoSearch />
          </div>
        </div>
        <div className="absolute bottom-[2vh] visible nft-footer-bg-gradient dark:invisible dark:h-0 left-0 right-0 h-[11vh]">
          <Footer currentActive="/home" />
        </div>
        <div className="absolute bottom-[2vh] h-0 invisible dark:visible dark:h-[11vh] nft-footer-bg-gradient-dark left-0 right-0">
          <Footer currentActive="/home" />
        </div>
      </div>
    </>
  );
};
