import axios from "axios";
// import { SkipRouteParams, SkipRouteResponse } from "../../types";


export type SkipRouteParams = {
    amount_in: string;
    source_asset_denom: string;
    source_asset_chain_id: string | number;
    dest_asset_denom: string;
    dest_asset_chain_id: string | number;
    cumulative_affiliate_fee_bps: string;
    allow_multi_tx: boolean;
  };
  interface Operation {
    [key: string]: any; // Allows for dynamic keys with any value type
  }
  interface OriginAsset {
    denom: string;
    chain_id: string;
    origin_denom: string;
    origin_chain_id: string;
    trace: string;
    is_cw20: boolean;
    is_evm: boolean;
    is_svm: boolean;
    symbol: string;
    name: string;
    logo_uri: string;
    decimals: number;
    token_contract: string;
    description: string;
    coingecko_id: string;
    recommended_symbol: string;
  }
  
  interface EstimatedFee {
    fee_type: string;
    bridge_id: string;
    amount: string;
    usd_amount: string;
    origin_asset: OriginAsset;
    chain_id: string;
    tx_index: number;
  }
  export interface SkipRouteResponse {
    source_asset_denom: string;
    source_asset_chain_id: string;
    dest_asset_denom: string;
    dest_asset_chain_id: string;
    amount_in: string;
    amount_out: string;
    operations: Operation[];
    chain_ids: string[];
    does_swap: boolean;
    estimated_amount_out: string;
    swap_venues: any[];
    txs_required: number;
    usd_amount_in: string;
    usd_amount_out: string;
    estimated_fees: EstimatedFee[];
    required_chain_addresses: string[];
    estimated_route_duration_seconds: number;
  }
  

// use the fee estimation and route duration in seconds (estimated_route_duration_seconds) to choose up the route between different providers we use i.e. debridge, skip, etc.
export const getSkipRoute = async ({
    amount_in = "3000000000000000000",
    source_asset_denom = "polygon-native",
    source_asset_chain_id = "137",
    dest_asset_denom = "ibc/AB589511ED0DD5FA56171A39978AFBF1371DB986EC1C3526CE138A16377E39BB",
    dest_asset_chain_id = "osmosis-1",
    cumulative_affiliate_fee_bps = "0",
    allow_multi_tx = true,
  }: SkipRouteParams) => {
    try {
      let config = {
        method: "POST",
        maxBodyLength: Infinity,
        url: "https://api.skip.build/v2/fungible/route",
        data: JSON.stringify({
          amount_in,
          source_asset_denom,
          source_asset_chain_id: source_asset_chain_id.toString(),
          dest_asset_denom,
          dest_asset_chain_id,
          cumulative_affiliate_fee_bps,
          allow_multi_tx,
        }),
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      };
  
      const { data } = await axios.request(config);
  
      console.log({ data });
  
      return { success: true, data: data as SkipRouteResponse };
    } catch (err: any) {
      console.error(err);
      return { success: false, message: err?.message };
    }
  };
  