import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserState } from "./user.model";
import {
  AssetForTriaName,
  NftsItem,
  HistoryItem,
  RecentUser,
  UserOnChainStatus,
  AvatarItem,
} from "../../../types";

const initialState: UserState = {
  userAssets: [],
  userAssetsLastFetch: "",
  userNfts: [],
  userNftsLastFetch: "",
  userHistory: [],
  userRecentUsers: [],
  userStatusOnChain: {
    triaName: "dev@tria",
    chainName: "FUSE",
    type: "AA",
    address: "",
    aa: "",
  },
  avatar: {
    avatar: "",
    background: "",
  },
  fetchedOnce: false,
  isUserInactive: false,
  pinSetup: true,
};

export const userSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    updateUserAssetsLastFetch: (state, action: PayloadAction<string>) => {
      state.userAssetsLastFetch = action.payload;
    },
    updateUserNftsLastFetch: (state, action: PayloadAction<string>) => {
      state.userNftsLastFetch = action.payload;
    },
    updateUserAssets: (state, action: PayloadAction<AssetForTriaName[]>) => {
      state.userAssets = action.payload;
    },
    updateUserNfts: (state, action: PayloadAction<NftsItem[]>) => {
      state.userNfts = action.payload;
    },
    updateUserHistory: (state, action: PayloadAction<HistoryItem[]>) => {
      state.userHistory = action.payload;
    },
    updateRecentUsers: (state, action: PayloadAction<RecentUser[]>) => {
      state.userRecentUsers = action.payload;
    },
    updateUserAddressOnChain: (
      state,
      action: PayloadAction<UserOnChainStatus>
    ) => {
      state.userStatusOnChain = action.payload;
    },
    updateUserChain: (state, action: PayloadAction<string>) => {
      state.userStatusOnChain.chainName = action.payload;
    },
    updateUserAvatar: (state, action: PayloadAction<AvatarItem>) => {
      state.avatar = action.payload;
      state.fetchedOnce = true;
    },
    updateUserActivity: (state, action: PayloadAction<boolean>) => {
      state.isUserInactive = action.payload;
    },
    updatePinSetup: (state, action: PayloadAction<boolean>) => {
      state.pinSetup = action.payload;
    },
  },
});

export const {
  updateUserAssets,
  updateUserNfts,
  updateUserHistory,
  updateRecentUsers,
  updateUserAddressOnChain,
  updateUserAvatar,
  updateUserAssetsLastFetch,
  updateUserNftsLastFetch,
  updateUserChain,
  updateUserActivity,
  updatePinSetup,
} = userSlice.actions;
