import { LiFi, RouteOptions, RoutesRequest } from "@lifi/sdk";
const integrator = "tria";

const routeOptions: RouteOptions = {
  infiniteApproval: true,
  integrator,
};

export const getRoutes = async (routeParams: RoutesRequest) => {
  //      const routesRequest:  = {
  //        fromChainId,
  //        fromAmount,
  //        fromTokenAddress,
  //        toChainId,
  //        toTokenAddress,
  //        options: routeOptions,
  //      };
  // };
  const lifi = new LiFi({
    defaultRouteOptions: routeOptions,
    integrator,
  });

  const result = await lifi.getRoutes(routeParams);
  return result.routes;
};
