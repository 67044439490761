import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Conversation, NftsItemDetails } from "../../../types";
import { nftDetail_initialstate } from "./caches.initialstate";
import { CacheState } from "./caches.model";

const INITIAL_STATE: CacheState = {
  nftDetail: nftDetail_initialstate,
  onChainConversation: [],
};

export const cacheSlice = createSlice({
  name: "Caches",
  initialState: INITIAL_STATE,
  reducers: {
    updateNftDetailCache: (state, action: PayloadAction<NftsItemDetails>) => {
      state.nftDetail = action.payload;
    },
    updateConversationCache: (state, action: PayloadAction<Conversation[]>) => {
      state.onChainConversation = action.payload;
    },
  },
});

export const { updateConversationCache, updateNftDetailCache } =
  cacheSlice.actions;
