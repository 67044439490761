import React, { useState } from "react";
import { Footer, Nav } from "../../package/ui.common";
import { useLocation, useNavigate } from "react-router-dom";
import { updateCurrentRoute } from "../../package/rx.core";
import { useDispatch } from "react-redux";
import { BackButton } from "../../package/ui.common/src/components/Buttons/BackButton";
import TransactionPreferenceSlider from "../../package/ui.common/src/components/Swap/TransactionPreferenceSlider";

export const SwapPreferences: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const isLite = queryParams.get("lite") === "true";

  dispatch(updateCurrentRoute(window.location.pathname?.toString()));

  const HandleClick = () => {
    if (isLite) {
      navigate("/lite");
    } else {
      navigate("/home");
    }
  };

  return (
    <div>
      <div
        className={`${
          isLite
            ? "w-[100vw] dark:bg-primaryDarkColorLite"
            : "w-[53vh] dark:bg-primaryDarkColor "
        } flex  h-[100vh] px-[1.4vh] items-center justify-center relative  bg-primaryColor `}
      >
        <div className="w-[100%] h-[98%] py-[2vh] flex-col justify-center items-center gap-[1.4vh] inline-flex  rounded-xl font-montserrat">
          <div className="w-full grow shrink basis-0 flex-col justify-start items-center flex">
            <Nav lite={isLite} />

            <div
              className={`${
                isLite ? "text-sm" : "px-[2vh text-[2.4vh]]"
              } w-full h-[6.4vh]  py-[1.67vh] rounded-[2.4vh] flex-col justify-start items-center gap-[1.4vh] inline-flex`}
            >
              <div className="self-stretch justify-start items-center gap-[2vh] inline-flex">
                <div className="h-[2.8vh] justify-start items-center gap-[1vh] flex">
                  {isLite && (
                    <div className="w-5 h-5 flex items-center">
                      <BackButton onClick={HandleClick} />
                    </div>
                  )}
                  {!isLite && (
                    <div className="w-[2vh] h-[2vh] justify-center items-center flex">
                      <div className="w-[1.67vh] h-[1.67vh] relative">
                        <BackButton onClick={HandleClick} />
                      </div>
                    </div>
                  )}

                  <div className="flex text-center items-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80  font-semibold leading-normal">
                    Swap/Bridge Preference
                  </div>
                </div>
              </div>
            </div>
            <TransactionPreferenceSlider />
          </div>
        </div>
      </div>
      {!isLite && (
        <>
          <div className="absolute bottom-[2vh] visible nft-footer-bg-gradient dark:invisible dark:h-0 left-0 right-0 h-[11vh]">
            <Footer currentActive="/home" />
          </div>
          <div className="absolute bottom-[2vh] h-0 invisible dark:visible dark:h-[11vh] nft-footer-bg-gradient-dark left-0 right-0">
            <Footer currentActive="/home" />
          </div>
        </>
      )}
    </div>
  );
};
