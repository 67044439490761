import { useTriaUser } from "../package/ui.common/src/contexts/tria-user-provider/useTriaUser";
import { PopularTokenItem } from "../package/rx.core";
import { useEffect, useState } from "react";

export function useFetchPopularCrypto() {
  const [popularToken, setPopularToken] = useState<PopularTokenItem[]>();
  const [loading, setLoading] = useState(true);
  const { getPopularToken } = useTriaUser();
  useEffect(() => {
    const fetchPopularToken = async () => {
      try {
        const response = await getPopularToken();
        setLoading(false);
        setPopularToken(response.token);
      } catch (error) {
        console.error(error);
        const { message } = error as Error;
        return Promise.reject(String(message || error));
      }
    };
    fetchPopularToken();
  }, []);
  return { popularToken, loading };
}
