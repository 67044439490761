interface Props {
  content: string;
  onClick?: () => void;
  height?: string;
  width?: string;
  disable?: boolean;
  liteMode?: boolean;
}

export const PrimaryCustomButton: React.FC<Props> = ({
  content,
  onClick: handleClick,
  height,
  width,
  disable = false,
  liteMode,
}) => {
  if (liteMode)
    return (
      <>
        {!disable && (
          <div
            className="p-[2vh] bg-gradient-to-r bg-white rounded-[7.1vh] justify-center items-center flex cursor-pointer relative group"
            style={{ width: width, height: height }}
            onClick={handleClick}
          >
            <div className="bg-[#000000] group-hover:opacity-20 opacity-0 absolute top-0 left-0 w-full h-full rounded-[7.1vh]" />
            <div className="justify-center items-center flex">
              <div className="text-center text-black text-[2.4vh] font-semibold  leading-normal">
                {content}
              </div>
            </div>
          </div>
        )}
        {disable && (
          <div
            className="p-[2vh] bg-white opacity-40 rounded-[7.1vh] justify-center items-center flex cursor-not-allowed relative group"
            style={{ width: width, height: height }}
            onClick={handleClick}
          >
            <div className="bg-[#000000] group-hover:opacity-20 opacity-0 absolute top-0 left-0 w-full h-full rounded-[7.1vh]" />
            <div className="justify-center items-center flex">
              <div className="text-center text-black text-[2.4vh] font-semibold  leading-normal">
                {content}
              </div>
            </div>
          </div>
        )}
      </>
    );

  return (
    <>
      {!disable && (
        <div
          className="p-[2vh] bg-gradient-to-r from-violet-400 to-indigo-500 rounded-[7.1vh] justify-center items-center flex cursor-pointer relative group"
          style={{ width: width, height: height }}
          onClick={handleClick}
        >
          <div className="bg-[#000000] group-hover:opacity-20 opacity-0 absolute top-0 left-0 w-full h-full rounded-[7.1vh]" />
          <div className="justify-center items-center flex">
            <div className="text-center text-white text-[2.4vh] font-semibold  leading-normal">
              {content}
            </div>
          </div>
        </div>
      )}
      {disable && (
        <div
          className="p-[2vh] dark:bg-[#D0D0D0] bg-[#606060] rounded-[7.1vh] justify-center items-center flex cursor-not-allowed opacity-30 relative group"
          style={{ width: width, height: height }}
          onClick={handleClick}
        >
          <div className="bg-[#000000] group-hover:opacity-20 opacity-0 absolute top-0 left-0 w-full h-full rounded-[7.1vh]" />
          <div className="justify-center items-center flex">
            <div className="text-center text-[rgba(16, 16, 16, 0.30)] text-[2.4vh] font-semibold  leading-normal">
              {content}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
