import axios from "axios";
import { lifiUrl } from "@tria-sdk/constants";

export const getTokens = async () => {
  try {
    const tokens = await axios.get(`${lifiUrl}/tokens`);
    return tokens.data;
  } catch (error) {
    console.error(`Error in getTokens: ${error}`);
    throw Error(`Error in getTokens: ${error}`);
  }
};
