import {
  bitlayerTestnet,
  bitlayerMainnet,
  neoxTestnet,
  polygonAmoy,
  sepolia,
  zkLink,
  zkLinkTestnet,
  vanarTestnet,
  skaleTestnet,
  solanaDevnet,
  injective,
  injectiveTestnet,
  matChain,
  matChainTestnet,
} from "./chains";

export const explorerBaseUrls = {
  ETH: "https://etherscan.io",
  [sepolia]: "https://sepolia.etherscan.io",
  POLYGON: "https://polygonscan.com",
  [polygonAmoy]: "https://www.oklink.com/amoy",
  AVALANCHE: "https://snowtrace.io",
  ARBITRUM: "https://arbiscan.io",
  BINANCE: "https://bscscan.com",
  OPTIMISM: "https://optimistic.etherscan.io",
  FANTOM: "https://ftmscan.com",
  SOLANA: "https://solscan.io",
  [solanaDevnet]: "https://solscan.io",
  APTOS: "https://explorer.aptoslabs.com",
  NEO: "https://neo3.neotube.io",
  SUI: "https://suiexplorer.com/?network=mainnet",
  FUSE: "https://explorer.fuse.io",
  FUSESPARK: "https://explorer.fusespark.io",
  MUMBAI: "https://mumbai.polygonscan.com",
  METIS: "https://andromeda-explorer.metis.io",
  "METIS-TESTNET": "https://sepolia.explorer.metisdevops.link",
  MANTLE: "https://explorer.mantle.xyz",
  "MANTLE-TESTNET": "https://explorer.testnet.mantle.xyz",
  MANTA: "https://pacific-explorer.manta.network",
  "MANTA-TESTNET": "https://manta-testnet.calderaexplorer.xyz",
  [zkLink]: "https://explorer.zklink.io",
  [zkLinkTestnet]: "https://goerli.explorer.zklink.io",
  [bitlayerTestnet]: "https://testnet-scan.bitlayer.org/en-us",
  [bitlayerMainnet]: "https://www.btrscan.com",
  [neoxTestnet]: "https://xt2scan.ngd.network",
  [vanarTestnet]: "https://explorer-vanguard.vanarchain.com",
  [skaleTestnet]:
    "https://giant-half-dual-testnet.explorer.testnet.skalenodes.com",
  [injective]: "https://explorer.injective.network",
  [injectiveTestnet]: "https://testnet.explorer.injective.network",
  [matChainTestnet]: "https://testnet.matchscan.io",
  [matChain]: "https://www.matchscan.io"
};

export const txnBaseUrl = {
  ETH: `${explorerBaseUrls.ETH}/tx`, // /txHash
  [sepolia]: `${explorerBaseUrls[sepolia]}/tx`,
  POLYGON: `${explorerBaseUrls.POLYGON}/tx`,
  [polygonAmoy]: `${explorerBaseUrls[polygonAmoy]}/tx`,
  AVALANCHE: `${explorerBaseUrls.AVALANCHE}/tx`,
  ARBITRUM: `${explorerBaseUrls.ARBITRUM}/tx`,
  BINANCE: `${explorerBaseUrls.BINANCE}/tx`,
  OPTIMISM: `${explorerBaseUrls.OPTIMISM}/tx`,
  FANTOM: `${explorerBaseUrls.FANTOM}/tx`,
  FUSE: `${explorerBaseUrls.FUSE}/tx`,
  FUSESPARK: `${explorerBaseUrls.FUSESPARK}/tx`,
  MUMBAI: `${explorerBaseUrls.MUMBAI}/tx`,
  METIS: `${explorerBaseUrls.METIS}/tx`,
  "METIS-TESTNET": `${explorerBaseUrls["METIS-TESTNET"]}/tx`,
  MANTLE: `${explorerBaseUrls.MANTLE}/tx`,
  "MANTLE-TESTNET": `${explorerBaseUrls["MANTLE-TESTNET"]}/tx`,
  MANTA: `${explorerBaseUrls.MANTA}/tx`,
  "MANTA-TESTNET": `${explorerBaseUrls["MANTA-TESTNET"]}/tx`,
  [zkLink]: `${explorerBaseUrls[zkLink]}/tx`,
  [zkLinkTestnet]: `${explorerBaseUrls[zkLinkTestnet]}/tx`,
  [bitlayerTestnet]: `${explorerBaseUrls[bitlayerTestnet]}/tx`,
  [bitlayerMainnet]: `${explorerBaseUrls[bitlayerMainnet]}/tx`,
  [neoxTestnet]: `${explorerBaseUrls[neoxTestnet]}/tx`,
  [vanarTestnet]: `${explorerBaseUrls[vanarTestnet]}/tx`,
  [skaleTestnet]: `${explorerBaseUrls[skaleTestnet]}/tx`,
  SOLANA: `${explorerBaseUrls.SOLANA}/tx`,
  [solanaDevnet]: `${explorerBaseUrls[solanaDevnet]}/tx`,
  [injective]: `${explorerBaseUrls[injective]}/transaction`,
  [injectiveTestnet]: `${explorerBaseUrls[injectiveTestnet]}/transaction`,
  [matChain]: `${explorerBaseUrls[matChain]}/tx`,
  [matChainTestnet]: `${explorerBaseUrls[matChainTestnet]}/tx`
};

export const nativeTokenDetails = {
  ETH: {
    name: "Ether",
    symbol: "ETH",
    logoUrl: "https://www.datocms-assets.com/86369/1669619533-ethereum.png",
  },
  [sepolia]: {
    name: "Ether",
    symbol: "ETH",
    logoUrl: "https://www.datocms-assets.com/86369/1669619533-ethereum.png",
  },
  POLYGON: {
    name: "Matic Token",
    symbol: "MATIC",
    logoUrl:
      "https://logos.covalenthq.com/tokens/1/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png",
  },
  [polygonAmoy]: {
    name: "Matic Token",
    symbol: "MATIC",
    logoUrl:
      "https://logos.covalenthq.com/tokens/1/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png",
  },
  AVALANCHE: {
    name: "Avalanche Coin",
    symbol: "AVAX",
    logoUrl:
      "https://www.datocms-assets.com/86369/1686152997-avalanche-colour.png",
  },
  ARBITRUM: {
    name: "Arbitrum Mainnet Ether",
    symbol: "ETH",
    logoUrl: "https://www.datocms-assets.com/86369/1669925028-arbitrum.png",
  },
  BINANCE: {
    name: "Binance Coin",
    symbol: "BNB",
    logoUrl:
      "https://www.datocms-assets.com/86369/1670003837-biance-smart-chain-colour.png",
  },
  OPTIMISM: {
    name: "Ether",
    symbol: "ETH",
    logoUrl:
      "https://www.datocms-assets.com/86369/1670347461-optimisim-colour.png",
  },
  FANTOM: {
    name: "Fantom",
    symbol: "FTM",
    logoUrl: "https://www.datocms-assets.com/86369/1669925359-fantom-1.png",
  },
  FUSE: {
    name: "Fuse",
    symbol: "FUSE",
    logoUrl:
      "https://assets.coingecko.com/coins/images/10347/standard/fuse.png",
  },
  FUSESPARK: {
    name: "Spark",
    symbol: "SPARK",
    logoUrl:
      "https://assets.coingecko.com/coins/images/10347/standard/fuse.png",
  },
  MUMBAI: {
    name: "Matic Token",
    symbol: "MATIC",
    logoUrl:
      "https://logos.covalenthq.com/tokens/1/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png",
  },
  METIS: {
    name: "Metis Token",
    symbol: "METIS",
    logoUrl: "https://static.tria.so/chain-logo-w/Metis.webp",
  },
  "METIS-TESTNET": {
    name: "Metis Token",
    symbol: "METIS",
    logoUrl: "https://static.tria.so/chain-logo-w/Metis.webp",
  },
  MANTLE: {
    name: "Mantle",
    symbol: "MNT",
    logoUrl: "https://static.tria.so/chain-logo-w/Mantle.webp",
  },
  "MANTLE-TESTNET": {
    name: "Mantle",
    symbol: "MNT",
    logoUrl: "https://static.tria.so/chain-logo-w/Mantle.webp",
  },
  MANTA: {
    name: "Ether",
    symbol: "ETH",
    logoUrl: "https://static.tria.so/chain-logo-w/Manta.webp",
  },
  "MANTA-TESTNET": {
    name: "Ether",
    symbol: "ETH",
    logoUrl: "https://static.tria.so/chain-logo-w/Manta.webp",
  },
  SOLANA: {
    name: "Solana",
    symbol: "SOL",
    logoUrl: "https://static.tria.so/chain-logo-w/Solana.webp",
  },
  [solanaDevnet]: {
    name: "Solana",
    symbol: "SOL",
    logoUrl: "https://static.tria.so/chain-logo-w/Solana.webp",
  },
  [zkLink]: {
    name: "Ether",
    symbol: "ETH",
    logoUrl: "https://static.tria.so/chain-logo-w/zklink.webp",
  },
  [zkLinkTestnet]: {
    name: "Ether",
    symbol: "ETH",
    logoUrl: "https://static.tria.so/chain-logo-w/zklink.webp",
  },
  [bitlayerTestnet]: {
    name: "Bitcoin",
    symbol: "BTC",
    logoUrl: "https://static.tria.so/chain-logo-w/bitlayer.webp",
  },
  [bitlayerMainnet]: {
    name: "Bitcoin",
    symbol: "BTC",
    logoUrl: "https://static.tria.so/chain-logo-w/bitlayer.webp",
  },
  [neoxTestnet]: {
    name: "Gas",
    symbol: "GAS",
    logoUrl:
      "https://assets.coingecko.com/coins/images/858/standard/GAS_512_512.png?1696501992",
  },
  [vanarTestnet]: {
    name: "Vanry",
    symbol: "VG",
    logoUrl:
      "https://assets.coingecko.com/coins/images/33466/standard/apple-touch-icon.png?1701942541",
  },
  [skaleTestnet]: {
    name: "sFUEL",
    symbol: "sFUEL",
    logoUrl:
      "https://assets.coingecko.com/coins/images/13564/standard/SFUEL.png?1696513318",
  },
  [injective]: {
    name: "Injective",
    symbol: "INJ",
    logoUrl: "https://static.tria.so/chain-logo-w/Injective.webp",
  },
  [injectiveTestnet]: {
    name: "Injective",
    symbol: "INJ",
    logoUrl: "https://static.tria.so/chain-logo-w/Injective.webp",
  },
  [matChain]: {
    name: "BNB",
    symbol: "BNB",
    logoUrl: "",    // TODO: Add logo
  },
  [matChainTestnet]: {
    name: "tBNB",
    symbol: "tBNB",
    logoUrl: "",    // TODO: Add logo
  },
};

const alchemyKey = "kcuWVV9ss_iLWJ2Lw6xdHbmtZYixfY7Z";

export const rpcUrls = {
  MUMBAI: `https://polygon-mumbai.g.alchemy.com/v2/${alchemyKey}`, //'https://polygon-mumbai.blockpi.network/v1/rpc/public',
  [polygonAmoy]: `https://polygon-amoy.g.alchemy.com/v2/${alchemyKey}`,
  [sepolia]: `https://eth-sepolia.g.alchemy.com/v2/${alchemyKey}`,
  ETH: `https://eth-mainnet.g.alchemy.com/v2/${alchemyKey}`, //"https://eth.llamarpc.com",
  POLYGON: `https://polygon-mainnet.g.alchemy.com/v2/${alchemyKey}`, // 'https://polygon.llamarpc.com',
  AVALANCHE: `https://avax-mainnet.g.alchemy.com/v2/${alchemyKey}`, //"https://avalanche-c-chain.publicnode.com",
  ARBITRUM: `https://arb-mainnet.g.alchemy.com/v2/${alchemyKey}`, //"https://arbitrum-one.publicnode.com",
  BINANCE: `https://bnb-mainnet.g.alchemy.com/v2/${alchemyKey}`, //"https://bsc.publicnode.com",
  OPTIMISM: `https://opt-mainnet.g.alchemy.com/v2/${alchemyKey}`, //"https://optimism.meowrpc.com",
  FANTOM: `https://fantom-mainnet.g.alchemy.com/v2/${alchemyKey}`, //"https://fantom.publicnode.com",
  FUSE: "https://rpc.fuse.io",
  FUSESPARK: "https://rpc.fusespark.io",
  MANTA: "https://1rpc.io/manta",
  "MANTA-TESTNET": "https://manta-testnet.calderachain.xyz/http",
  METIS: "https://metis-pokt.nodies.app",
  "METIS-TESTNET": "https://goerli.gateway.metisdevops.link",
  MANTLE: "https://rpc.mantle.xyz",
  "MANTLE-TESTNET": "https://rpc.testnet.mantle.xyz",
  [zkLink]: "https://rpc.zklink.io",
  [zkLinkTestnet]: "https://goerli.rpc.zklink.io",
  [bitlayerTestnet]: "https://testnet-rpc.bitlayer.org",
  [bitlayerMainnet]: "https://rpc.bitlayer.org",
  [neoxTestnet]: "https://neoxseed1.ngd.network",
  [vanarTestnet]: "http://rpc-partners-vanguard.vanarchain.com", // "https://rpc-vanguard.vanarchain.com",
  [skaleTestnet]: "https://testnet.skalenodes.com/v1/giant-half-dual-testnet",
  [matChain]: "https://rpc.matchscan.io",
  [matChainTestnet]: "https://testnet-rpc.matchain.io",
};

export const chainNameToChainId = {
  MUMBAI: 80001,
  [polygonAmoy]: 80002,
  [sepolia]: 11155111,
  POLYGON: 137,
  ETH: 1,
  AVALANCHE: 43114,
  ARBITRUM: 42161,
  BINANCE: 56,
  OPTIMISM: 10,
  FANTOM: 250,
  FUSE: 122,
  FUSESPARK: 123,
  MANTA: 169,
  "MANTA-TESTNET": 3441005,
  METIS: 1088,
  "METIS-TESTNET": 599,
  MANTLE: 5000,
  "MANTLE-TESTNET": 5001,
  [zkLink]: 810180,
  [zkLinkTestnet]: 810182,
  [bitlayerTestnet]: 200810,
  [bitlayerMainnet]: 200901,
  [neoxTestnet]: 12227330,
  [vanarTestnet]: 78600,
  [skaleTestnet]: 974399131,
  [matChain]: 698,
  [matChainTestnet]: 699,
};

export const chainIdToChainName = {
  11155111: sepolia,
  80001: "MUMBAI",
  80002: polygonAmoy,
  137: "POLYGON",
  1: "ETH",
  43114: "AVALANCHE",
  42161: "ARBITRUM",
  56: "BINANCE",
  10: "OPTIMISM",
  250: "FANTOM",
  122: "FUSE",
  123: "FUSESPARK",
  5000: "MANTLE",
  5001: "MANTLE-TESTNET",
  1088: "METIS",
  599: "METIS-TESTNET",
  169: "MANTA",
  3441005: "MANTA-TESTNET",
  810180: zkLink,
  810182: zkLinkTestnet,
  200810: bitlayerTestnet,
  200901: bitlayerMainnet,
  12227330: neoxTestnet,
  78600: vanarTestnet,
  974399131: skaleTestnet,
  698: matChain,
  699: matChainTestnet,
};
