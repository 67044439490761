import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTriaName } from "../../../../../hooks/useTriaName";
import {
  ChainAddressData,
  formatTriaName,
  RootState,
  sendCloseWalletMessage,
  sendOverlayMessage,
  updateAANetworks,
  updateNetworks,
  updateRefreshLoad,
  updateReload,
} from "../../../../rx.core";
import { getWalletStoreLocalStorage } from "../../../../rx.core/src/utils/getWalletStoreLocalStorage";
import { Refresh, UserMenuLite } from "../../components";
import { ArrowButton, CopyButton } from "../../components/Buttons";
import { UserAvatar } from "../../components/Containers/user/UserAvatar";
import Accounts from "../../components/Popups/Accounts";
import Chains from "../../components/Popups/Chains";
import { UserMenu } from "../../components/Popups/UserMenu";
import { useTriaUser } from "../../contexts";
import { useReload } from "../../contexts/reload/ReloadContext";
import {
  ALLCHAINS,
  isAaSupportedChain,
  isEvmChain,
  isTestnetChain,
} from "@tria-sdk/constants";
import { CustomChainData } from "@tria-sdk/core";
import { v4 as uuidv4 } from "uuid";

interface NavProps {
  lite?: boolean;
  isQrOpen?: boolean;
  isAccountsOpen?: boolean;
  isAccountsExpanded?: boolean;
  setIsAccountsOpen?: Dispatch<SetStateAction<boolean>>;
  setIsQrOpen?: Dispatch<SetStateAction<boolean>>;
  setAccountsExpanded?: Dispatch<SetStateAction<boolean>>;
}

export const Nav: React.FC<NavProps> = ({
  lite = false,
  isQrOpen: isQrOpenProp,
  isAccountsOpen: isAccountsOpenProp,
  isAccountsExpanded: isAccountsExpandedProp,
  setIsAccountsOpen: setIsAccountsOpenProp,
  setIsQrOpen: setIsQrOpenProp,
  setAccountsExpanded: setAccountsExpandedProp,
}) => {
  // Local state for when props aren't provided
  const [localIsQrOpen, setLocalIsQrOpen] = useState(false);
  const [localIsAccountsOpen, setLocalIsAccountsOpen] = useState(false);
  const [localIsAccountsExpanded, setLocalIsAccountsExpanded] = useState(false);

  // Use prop values if provided, otherwise use local state
  const isQrOpen = isQrOpenProp ?? localIsQrOpen;
  const isAccountsOpen = isAccountsOpenProp ?? localIsAccountsOpen;
  const isAccountsExpanded = isAccountsExpandedProp ?? localIsAccountsExpanded;

  // Combined setters that use props if available, otherwise use local state
  const setIsQrOpen = useCallback(
    (value: boolean | ((prev: boolean) => boolean)) => {
      if (setIsQrOpenProp) {
        setIsQrOpenProp(value);
      } else {
        setLocalIsQrOpen(value);
      }
    },
    [setIsQrOpenProp]
  );

  const setIsAccountsOpen = useCallback(
    (value: boolean | ((prev: boolean) => boolean)) => {
      if (setIsAccountsOpenProp) {
        setIsAccountsOpenProp(value);
      } else {
        setLocalIsAccountsOpen(value);
      }
    },
    [setIsAccountsOpenProp]
  );

  const setAccountsExpanded = useCallback(
    (value: boolean | ((prev: boolean) => boolean)) => {
      if (setAccountsExpandedProp) {
        setAccountsExpandedProp(value);
      } else {
        setLocalIsAccountsExpanded(value);
      }
    },
    [setAccountsExpandedProp]
  );

  const [isChainToggleOpen, setIsChainToggleOpen] = useState(false);
  const [isUserPopupOpen, setIsUserPopupOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>();

  const { triaName } = useTriaName();
  const { reload, toggleReload } = useReload();
  const { getAllNetworks } = useTriaUser();
  const dispatch = useDispatch();

  const refreshLoading = useSelector(
    (store: RootState) => store?.AppState?.AppCurrentState?.refreshLoading
  );
  const networkItems = useSelector(
    (store: RootState) => store?.Constants?.NetworkItems
  );
  const userStatus = useSelector(
    (store: RootState) => store.User.userStatusOnChain
  );
  const showTestNets = useSelector(
    (store: RootState) => store.AppState?.AppCurrentState?.showTestNets
  );

  const selectRef = useRef<HTMLDivElement>(null);

  const {
    userAddresses,
    userNonEvmAddresses,
    supportedChains,
    priorityChains,
  } = useMemo(() => {
    let evmAddress = "";
    let aaAddress = "";
    let chainAddresses: ChainAddressData[] = [];
    let supportedChains: string[] = [...ALLCHAINS];
    const parsedData = getWalletStoreLocalStorage();
    if (parsedData) {
      evmAddress = parsedData?.addresses?.evm?.address || "";
      aaAddress = parsedData?.addresses?.aa?.address || "";

      supportedChains = supportedChains.filter(
        (chainName) => showTestNets || !isTestnetChain(chainName)
      );

      if (parsedData?.supportedChains) {
        supportedChains = Array.from(
          new Set(
            (parsedData.supportedChains as (string | CustomChainData)[])
              .map((chain) => {
                if (typeof chain === "string") return chain;
                if (
                  typeof chain === "object" &&
                  chain !== null &&
                  "chainName" in chain
                )
                  return chain.chainName || "";
                return "";
              })
              .filter(Boolean)
              .filter((chainName) => showTestNets || !isTestnetChain(chainName))
          )
        );
      }

      chainAddresses = supportedChains
        .filter((chainName) => isEvmChain(chainName))
        .map((chainName) => ({
          id: uuidv4(),
          chainName,
          address: isAaSupportedChain(chainName) ? aaAddress : evmAddress,
          isAA: isAaSupportedChain(chainName),
          isEvm: true,
        }));

      if (parsedData?.addresses?.nonEvm) {
        parsedData.addresses.nonEvm.forEach((el) => {
          if (
            supportedChains.includes(el.chainName) &&
            (showTestNets || !isTestnetChain(el.chainName))
          ) {
            chainAddresses.push({
              id: uuidv4(),
              chainName: el.chainName,
              address: el.address,
              isAA: false,
              isEvm: false,
            });
          }
        });
      }
    }

    const priorityChains = parsedData?.priorityChains;

    return {
      userAddresses: chainAddresses.filter((chain) => chain.isEvm),
      userNonEvmAddresses: chainAddresses.filter((chain) => !chain.isEvm),
      supportedChains,
      priorityChains,
    };
  }, [showTestNets]);

  const fetchNetworks = useCallback(async () => {
    if (!networkItems.length || reload) {
      try {
        const networks = await getAllNetworks("EOA");
        const AAnetworks = await getAllNetworks("AA");
        dispatch(updateNetworks(networks));
        dispatch(updateAANetworks(AAnetworks));
      } catch (error: any) {
        console.error(error);
      }
    }
  }, [dispatch, getAllNetworks, networkItems.length, reload]);

  useEffect(() => {
    if (reload) {
      fetchNetworks();
    }
  }, [fetchNetworks, reload]);

  useEffect(() => {
    fetchNetworks();
  }, [fetchNetworks]);

  const activeChain = useMemo(() => {
    return networkItems.find(
      (network: any) => network?.chainName === userStatus?.chainName
    );
  }, [networkItems, userStatus?.chainName]);
  console.log("activechain", userStatus);
  const handleAllAddressesClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    sendOverlayMessage("showOverlay", true);
    setIsAccountsOpen(!isAccountsOpen);
  };

  const handlePopUp = () => {
    setIsChainToggleOpen(false);
  };

  useEffect(() => {
    const checkIfClickedOutside = (event: MouseEvent) => {
      if (
        (isChainToggleOpen || isAccountsOpen || isUserPopupOpen) &&
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsChainToggleOpen(false);
        sendOverlayMessage("showOverlay", false);
        setIsAccountsOpen(false);
        setIsUserPopupOpen(false);
        setIsQrOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    return () =>
      document.removeEventListener("mousedown", checkIfClickedOutside);
  }, [isChainToggleOpen, isAccountsOpen, isUserPopupOpen]);

  const handleReload = () => {
    localStorage.removeItem("persist:root");
    toggleReload(true);
    dispatch(updateReload(true));
    dispatch(updateRefreshLoad(true));
    setLoading(true);
  };

  const closeWallet = () => {
    sendCloseWalletMessage();
  };

  useEffect(() => {
    if (!refreshLoading) {
      toggleReload(false);
      setLoading(false);
    } else {
      toggleReload(false);
      setLoading(true);
    }
  }, [refreshLoading, toggleReload]);

  const setQrState = (value: boolean) => {
    setIsQrOpen(value);
  };

  const embedMode = localStorage.getItem("embedded");

  return (
    <div
      className={`bg-primaryColor ${
        lite ? "dark:bg-primaryDarkColorLite" : "dark:bg-primaryDarkColor"
      } self-stretch h-[10vh] py-[2vh] rounded-[2vh] flex-col justify-center items-center gap-[1vh] z-[200] flex relative`}
    >
      {isChainToggleOpen && !lite && (
        <>
          <div className="w-[100%] h-[100%] fixed bg-[#101010] opacity-60 top-0 left-0 z-50" />
          <div
            className={`absolute top-[7vh] right-[1.2vh] flex bg-primaryColor dark:bg-primaryDarkColor rounded-[2vh] z-50 opacity-100 ${
              isChainToggleOpen ? "" : "hidden"
            }`}
            ref={selectRef}
            id="chain-toggle-ref"
          >
            <Chains network={networkItems} onClick={handlePopUp} />
          </div>
        </>
      )}
      {isAccountsOpen && (
        <>
          <div className="w-[100%] h-[100%] fixed bg-[#101010] opacity-60 top-0 right-0 z-50" />
          <div
            className={`
        fixed shadow-[2vh] z-50
        ${
          isQrOpen || isAccountsExpanded
            ? "inset-0 flex items-center justify-center"
            : lite
            ? "top-14 right-5"
            : "top-14 right-[5vh]"
        }
        ${
          isQrOpen
            ? "mx-auto w-[80%]"
            : isAccountsExpanded
            ? "w-[80%] mx-auto"
            : lite
            ? ""
            : "w-[80%] h-auto"
        }
        ${isAccountsOpen ? "" : "hidden"}
        ${lite ? "" : "h-auto"}
        flex flex-col items-center
      `}
            ref={selectRef}
            id="accounts-toggle-ref"
          >
            <div className="w-full bg-primaryColor dark:bg-primaryDarkColor rounded-lg overflow-hidden">
              <Accounts
                userAddresses={userAddresses}
                userNonEvmAddresses={userNonEvmAddresses}
                supportedChains={supportedChains}
                priorityChains={priorityChains}
                lite={lite}
                qrHandler={setQrState}
                accountsExpanded={isAccountsExpanded}
                setAccountsExpanded={setAccountsExpanded}
              />
            </div>
            {(isAccountsExpanded || isQrOpen) && (
              <div className="mt-4 cursor-pointer">
                <img
                  src="/icons/close-popup.svg"
                  height={48}
                  width={48}
                  alt="Close"
                  onClick={() => {
                    setIsAccountsOpen(false);
                    setAccountsExpanded(false);
                    setIsQrOpen(false);
                  }}
                />
              </div>
            )}
          </div>
        </>
      )}
      {isUserPopupOpen && (
        <>
          <div className="w-[100%] h-[100%] fixed bg-[#101010] opacity-60 top-0 left-0 z-50" />
          <div
            className={`shadow-[2vh] absolute top-[7vh] left-0 flex p-[1vh] rounded-[1vh] z-50 ${
              isUserPopupOpen ? "" : "hidden"
            } ${lite ? "" : "w-[80%]"}`}
            ref={selectRef}
            id="user-toggle-ref"
          >
            {lite ? <UserMenuLite /> : <UserMenu />}
          </div>
        </>
      )}
      <div
        className={`self-stretch ${
          lite ? "px-3" : "p-[1vh]"
        } justify-between items-center inline-flex`}
      >
        <div
          className={`${
            lite ? "w-full" : "grow shrink basis-0"
          } h-[5.2vh] justify-between items-center flex`}
        >
          <div className="flex items-center">
            {(!embedMode || embedMode === "false") && lite && (
              <img
                src="/icons/back-arrow.svg"
                height={18}
                width={18}
                className="mr-3 cursor-pointer"
                onClick={closeWallet}
              />
            )}
            <div
              className="group justify-center items-center flex cursor-pointer"
              onClick={() => {
                setIsUserPopupOpen(!isUserPopupOpen);
                sendOverlayMessage("showOverlay", true);
              }}
              ref={selectRef}
              id="user-toggle-ref"
            >
              <UserAvatar
                width={lite ? "27px" : undefined}
                height={lite ? "27px" : undefined}
              />
              <div className="px-[1vh] justify-start items-center flex">
                <div
                  className={`text-center text-black dark:text-[#FAFAFACC] group-hover:dark:text-[#FFFFFF] bg-opacity-90 text-opacity-80 ${
                    lite ? "text-sm" : "text-[2.3vh]"
                  } font-semibold leading-tight`}
                >
                  {triaName && triaName?.length > 20
                    ? formatTriaName(triaName)
                    : triaName}
                </div>
              </div>
              <div
                className={`w-[2.8vh] h-[2.8vh] relative flex items-center justify-start group-hover:translate-y-[0.5vh] transform duration-300`}
              >
                <ArrowButton />
              </div>
            </div>
          </div>
          <div
            className={`justify-end items-center ${
              lite ? "gap-2" : "gap-[2vh]"
            } flex`}
          >
            <div
              className={`z-20 ${
                loading ? "rotate-animation" : ""
              } cursor-pointer`}
              onClick={handleReload}
            >
              <div
                className={`invisble h-0 dark:flex dark:h-full items-center ${
                  loading ? "" : "hover:rotate-[45deg]"
                } transform duration-300`}
              >
                <Refresh
                  color="#FAFAFA"
                  width={lite ? 16 : undefined}
                  height={lite ? 16 : undefined}
                />
              </div>
              <div
                className={`flex dark:hidden dark:w-0 dark:h-0 items-center ${
                  loading ? "" : "hover:rotate-[45deg]"
                } transform duration-300`}
              >
                <Refresh
                  color="#101010"
                  width={lite ? 18 : undefined}
                  height={lite ? 18 : undefined}
                />
              </div>
            </div>
            <div className={lite ? "" : "w-[2vh]"}>
              <CopyButton
                onClick={(event) => {
                  event.stopPropagation();
                  handleAllAddressesClick(event);
                }}
                width={lite ? "18px" : undefined}
                height={lite ? "18px" : undefined}
              />
            </div>
            {!lite && (
              <div
                className="w-[5vh] h-[5vh] backdrop-blur-[3.50px] p-[0.5vh] flex justify-center items-center cursor-pointer"
                onClick={() => {
                  setIsChainToggleOpen(!isChainToggleOpen);
                  sendOverlayMessage("showOverlay", true);
                }}
              >
                <div className="w-[5vh] h-[5vh]" />
                <img
                  className="rounded-[0.8vh] overflow-hidden hover:translate-y-[-0.5vh] transform duration-300"
                  src={activeChain?.logo}
                  alt="logo"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
