import { getTokens } from "@tria-sdk/connect";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { AssetForTriaName, NetworkItem } from "../../../../rx.core";
import { useTriaUser } from "../../contexts";

interface LifiToken {
  address: string;
  chainId: number;
  coinKey: string;
  decimals: number;
  logoURI: string;
  name: string;
  priceUSD: string;
  symbol: string;
}

interface ChainIdType {
  [key: string]: number;
}

const ChainId: ChainIdType = {
  MUMBAI: 80001,
  POLYGON: 137,
  AVALANCHE: 43114,
  ARBITRUM: 42161,
  OPTIMISM: 10,
  FANTOM: 250,
  GNOSIS: 100,
  FUSE: 122,
  ETH: 1,
  BINANCE: 56,
  SOLANA: 1151111081099710,
};
interface ToSelectDropDownProps {
  toToken: any;
  setToToken: any;
  setCustomToken: any;
  customToken: any;
  fromToken?: AssetForTriaName;
  liteMode?: boolean;
}
const recentCryptos = [
  {
    name: "1000BONK",
    symbol: "BONK",
    chainId: ChainId["SOLANA"],
    tokenAddress: "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263",
    decimals: 5,
    logoUrl: "/icons/memeCoinLogo.png",
    index: 1,
    chainName: "SOLANA",
    chainLogo: "/icons/solanaLogoSmall.png",
  },
  {
    name: "USDC",
    symbol: "USDC",
    chainId: 2525,
    tokenAddress:
      "ibc/2CBC2EA121AE42563B08028466F37B600F2D7D4282342DE938283CC3FB2BC00E",
    decimals: 6,
    logoUrl:
      "https://token-icons.s3.amazonaws.com/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png",
    index: 2,
    chainName: "INJECTIVE",
    chainLogo: "/icons/injective_symbol.svg",
  },
  // {
  //   name: "Spooncat",
  //   logoUrl: "/icons/spooncatlogo.png",
  //   index: 0,
  //   chainName: "SOLANA",
  //   chainLogo: "/icons/solanaLogoSmall.png",
  // },
];
const ToSelectDropDown2: React.FC<ToSelectDropDownProps> = ({
  toToken,
  setToToken,
  setCustomToken,
  fromToken,
  customToken,
  liteMode,
}) => {
  const { getAllNetworks } = useTriaUser();
  const [alltokensLifi, setAlltokensLifi] = useState<any>([]);
  const [toVisibleData, setToVisibleData] = useState<LifiToken[]>([]);
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  // const [allNetworks, setAllNetworks] = useState<NetworkItem[]>();
  const [selectedChain, setSelectedChain] = useState<NetworkItem>();
  // const [filterToTokensData, setFilterToTokensData] = useState<any[]>([]);
  // const [filterToChainData, setFilterToChainData] = useState<any[]>([]);
  // const [toSearchQueryLifiTokens, setToSearchQueryLifiTokens] =
  //   useState<string>("");
  // const [toSearchQueryLifiChains, setToSearchQueryLifiChains] =
  //   useState<string>("");
  // const [pageNumber, setPageNumber] = useState(1);
  const overRideFeatureFlag = useMemo(() => {
    const walletStore = localStorage.getItem("tria.wallet.store.app");
    if (!walletStore) {
      return undefined;
    }
    return JSON.parse(walletStore)?.overRideWalletFeatureFlags;
  }, []);
  // const toHandleSearchChangeLifiTokens = (
  //   e: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const q =
  //     e.target.value.trim() === ""
  //       ? toAlltokensLifi?.[toChainIdSelected]
  //       : toAlltokensLifi?.[toChainIdSelected]?.filter((token: any) =>
  //           token?.symbol?.toLowerCase().includes(e.target.value.toLowerCase())
  //         );
  //   setFilterToTokensData(q);
  //   setToSearchQueryLifiTokens(e.target.value);
  // };

  // const toHandleSearchChangeLifiChains = (
  //   e: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const q =
  //     e.target.value.trim() === ""
  //       ? toChainData // If search box is empty, show all tokens
  //       : toChainData?.filter((token: any) =>
  //           token?.name?.toLowerCase().includes(e.target.value.toLowerCase())
  //         );
  //   setFilterToChainData(q);
  //   setToSearchQueryLifiChains(e.target.value);
  // };

  // const handleToDropDownClick = () => {
  //   setToDropDownClick(!toDropDownClick);
  //   setOverlayVisible(!overlayVisible);
  // };

  // const handleToToggleDropdown = () => {
  //   setIsToDropdownOpen(!isToDropdownOpen);
  // };

  // Fetch more data when the user scrolls to the bottom
  // const handleScroll = () => {
  //   const ul: any = ulRef.current;

  //   if (ul.scrollTop + ul.clientHeight >= ul.scrollHeight - 20) {
  //     setPageNumber((prevPageNumber) => prevPageNumber + 1);
  //   }
  // };

  // const [hasSetToChainDataSelected, setHasSetToChainDataSelected] =
  //   useState(false);

  // Update visible data when data changes
  // useEffect(() => {
  //   setToVisibleData(
  //     toAlltokensLifi?.[chainDataSelected?.id]?.slice(0, pageNumber * 5)
  //   );
  // }, [toAlltokensLifi, fromChainIdSelected, chainDataSelected, pageNumber]);

  // Fetch more data when the page number changes
  // useEffect(() => {
  //   fetchData();
  // }, [pageNumber]);

  const fetchData = async () => {
    try {
      const tokens = await getTokens();
      setAlltokensLifi(tokens?.tokens);
      if (!fromToken) {
        console.error("No From Token found!");
        return;
      }
      setToVisibleData(
        tokens?.tokens[`${ChainId[fromToken?.chainName]}`]?.slice(0, 100)
      );
      // setToChainData(chains?.chains);
      // setToChainDataSelected(chains?.chains?.[0])
    } catch (err) {
      console.log(err);
    }
  };
  // const fetchNetworks = useCallback(async () => {
  //   const networkRes = await getAllNetworks();
  //   const filteredNetworks = networkRes?.filter((network) => {
  //     if (network?.type === "mainnet") {
  //       return network;
  //     }
  //   });
  //   setAllNetworks(filteredNetworks?.slice(0, 9));
  //   setSelectedChain(filteredNetworks[2]);
  // }, [getAllNetworks]);
  // useEffect(() => {
  //   fetchNetworks();
  // }, [fetchNetworks]);

  // useEffect(() => {
  //   if (selectedChain && alltokensLifi) {
  //     setToVisibleData(
  //       alltokensLifi[`${ChainId[selectedChain?.chainName?.toUpperCase()]}`]
  //     );
  //   }
  // }, [selectedChain, alltokensLifi]);
  useEffect(() => {
    console.log("toVisibleData", toVisibleData);
  }, [toVisibleData]);
  // useEffect(() => {

  //   setToChainIdSelected(toChainData[fromChainIdSelected].id);
  //   // setToChainIndexSelected(index)
  //   setToChainDataSelected(toChainData[fromChainIdSelected]);
  // }, [fromChainIdSelected]);
  const handleTokenSelect = useCallback(
    (item: LifiToken) => {
      console.log("alltoken", item);
      setToToken({
        name: item?.name,
        symbol: item?.symbol,
        logoUrl: item?.logoURI,
        chainName: selectedChain?.chainName,
        chainLogo: selectedChain?.logo,
        chainId: item?.chainId,
        tokenAddress: item?.address,
      });
      setShowDropDown(false);
      setCustomToken(null);
    },
    [selectedChain?.chainName, selectedChain?.logo, setCustomToken, setToToken]
  );
  return (
    <>
      {toToken || customToken ? (
        <div
          className=" self-stretch px-[2.88vh] py-[1.44vh] h-[10vh] dark:bg-zinc-900 bg-fontColorNftCardDark rounded-[8.8vh] flex-col justify-center items-center gap-[.96vh] inline-flex cursor-pointer"
          onClick={() => {
            setShowDropDown(true);
          }}
        >
          <div className="self-stretch justify-start items-center gap-[1.92vh] inline-flex">
            <div className="grow shrink basis-0 h-[4.8vh] justify-start items-center gap-[1.44vh] flex">
              <div className="w-[4.8vh] h-[4.8vh] relative shadow ">
                <img
                  alt="logo url"
                  src={customToken?.logoUrl || toToken?.logoUrl}
                  className=" rounded-[28vh]"
                />
                <div className="left-[3.2vh] top-[3vh] absolute justify-start items-center gap-[.7vh] inline-flex">
                  <div className="w-[2.4vh] h-[2.4vh] relative rounded backdrop-blur-[2.50px]">
                    <img
                      alt="chain logo"
                      src={customToken?.chainLogo || toToken?.logoUrl}
                      className="rounded border"
                    />
                  </div>
                </div>
              </div>
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-[.48vh] inline-flex">
                <div className="self-stretch justify-start items-center gap-[.48vh] inline-flex">
                  <div className="dark:text-neutral-50 font-montserrat text-zinc-500  md:text-base text-sm font-semibold  -tight">
                    {customToken?.name || toToken?.name || ""}
                  </div>
                </div>
                <div className="text-zinc-500 font-montserrat md:text-sm text-xs font-semibold uppercase">
                  {customToken?.chainName || toToken?.chainName || ""}
                </div>
              </div>
            </div>
            <div className="w-[2.6vh] h-[2.6vh] relative">
              <div className="w-[2.6vh] h-[2.6vh] left-0 top-0 absolute">
                <img
                  alt="arrow-right"
                  src="/icons/arrow-right2.svg"
                  className="dark:visible invisible w-0 dark:w-[2.6vh]"
                />
                <img
                  alt="arrow-left"
                  src="/icons/arrow-left.svg"
                  className="dark:invisible visible dark:w-0 rotate-180"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            className="self-stretch  px-[2.88vh] py-[1.44vh] dark:bg-zinc-900 h-[10vh] bg-fontColorNftCardDark rounded-[8.8vh] flex-col justify-center items-center gap-[.96vh] flex cursor-pointer"
            onClick={() => {
              if (fromToken) {
                fetchData();
                setShowDropDown(true);
              } else {
              }
            }}
          >
            <div className="self-stretch justify-start items-center gap-[1.92vh] inline-flex">
              <div className="grow shrink basis-0 h-[4.8vh] justify-start items-center gap-[1.44vh] flex">
                <div className="w-[4.8vh] h-[4.8vh] relative">
                  <div className="w-[4.8vh] h-[4.8vh] left-0 top-0 absolute dark:bg-zinc-800 bg-bgOfCircle rounded-[4.8vh]" />
                </div>
                <div className="grow shrink basis-0 flex-col justify-start items-start gap-[.48vh] inline-flex">
                  <div className="self-stretch justify-start items-center gap-[.48vh] inline-flex">
                    <div className="text-zinc-500 font-montserrat md:text-base text-sm font-semibold  -tight">
                      Select crypto
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[2.6vh] h-[2.6vh] relative">
                <div className="w-[2.6vh] h-[2.6vh] left-0 top-0 absolute">
                  <img
                    alt="arrow-right"
                    src="/icons/arrow-right2.svg"
                    className="dark:visible invisible w-0 dark:w-[2.6vh]"
                  />
                  <img
                    alt="arrow-left"
                    src="/icons/arrow-left.svg"
                    className="dark:invisible visible dark:w-0 rotate-180"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {showDropDown &&
        createPortal(
          <div className="h-full w-full absolute top-0 z-1001 flex items-center">
            <div className="backdrop-blur-sm h-full w-full z-1 absolute" />
            <div className="p-1 w-full h-[60%] relative dark:bg-stone-950 bg-white rounded-[2.4vh] shadow  border-zinc-500 border-opacity-60">
              <div className="flex-col justify-end items-center gap-[.96vh] flex">
                <div className="flex-col w-full pt-[1.44vh] justify-start items-start flex">
                  <div className="w-full px-[1.44vh] h-[8vh] pt-[1.44vh] pb-[.48vh] rounded-tl-[2.4vh] rounded-tr-[2.4vh]  flex-col justify-center items-start flex">
                    <div className="w-full rounded-lg justify-between items-center gap-[.96vh] flex ">
                      <div className="text-center font-montserrat dark:text-neutral-50 md:text-base text-sm font-semibold  -tight">
                        Select Crypto
                      </div>

                      <div className="w-[3.36vh] h-[3.36vh] relative">
                        <div
                          className="w-[3.36vh] h-[3.36vh] left-[0.01px] top-0 absolute cursor-pointer"
                          onClick={() => {
                            setShowDropDown(false);
                          }}
                        >
                          <img
                            alt="close"
                            src="/icons/close-circle-dark.svg"
                            className="dark:visible invisible w-0 dark:w-[3.36vh]"
                          />
                          <img
                            alt="close"
                            src="/icons/close-circle2.svg"
                            className="dark:invisible visible dark:w-0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="flex items-start justify-center  overflow-x-scroll overflow-y-hidden   w-[270px] ">
                    {allNetworks?.map((item) => (
                      <div
                        className="w-[50px] felx items-center justify-center rounded-[4px] overflow-hidden hover:scale-110 cursor-pointer"
                        onClick={() => setSelectedChain(item)}
                      >
                        <img
                          alt="chainlogos"
                          src={item?.logo}
                          className="w-[20px] h-[20px]"
                        />
                      </div>
                    ))}
                  </div> */}
                  {overRideFeatureFlag ? (
                    <div className="w-full  flex-col justify-center items-start gap-[2.4vh] flex">
                      <div className="justify-center items-start gap-[.96vh] w-full flex flex-col">
                        <div className=" dark:text-neutral-700 font-montserrat text-networkColor md:text-sm text-xs font-semibold  lowercase  tracking-wide">
                          Trending Swaps
                        </div>
                        <div className="grid grid-cols-2 gap-[8px] justify-between items-center w-[100%] h-auto">
                          {recentCryptos?.map((crypto) => (
                            <div
                              className="dark:bg-hoverDarkColor p-[12px] rounded-[16px] flex items-center justify-start gap-[6px] hover:scale-110 transition-all  transform-300"
                              onClick={() => {
                                setCustomToken(crypto);
                                setToToken(undefined);
                                setShowDropDown(false);
                              }}
                            >
                              <div className="relative">
                                <img
                                  alt="tokenimg"
                                  src={crypto.logoUrl}
                                  className="w-[30px] rounded-[30px] h-[30px]"
                                />
                                <img
                                  src={crypto.chainLogo}
                                  alt="chain"
                                  className="w-[15px] h-[15px] absolute right-[-5px] bottom-[-5px] bg-black rounded-[4px] border-[1px] border-solid border-[#FAFAFA]"
                                />
                              </div>
                              <div className="h-full flex items-center justify-center ">
                                <p className="text-center font-montserrat dark:text-neutral-50 text-fontPrimaryColor md:text-base text-sm font-semibold  -tight">
                                  {" "}
                                  {crypto?.name}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="w-full px-[1.44vh] dark:text-neutral-700 font-montserrat text-networkColor md:text-sm text-xs font-semibold lowercase  tracking-wide">
                  Other Crypto
                </div>
                <div className=" w-[280px]  flex flex-col  justify-start items-center gap-[.92vh] h-[200px] overflow-y-scroll overflow-x-hidden">
                  {toVisibleData?.map((item, index: any) => (
                    <div
                      className="w-full flex-col justify-start items-start gap-[.96vh] flex cursor-pointer rounded-[2.5vh] bg-primaryColor dark:bg-primaryDarkColor hover:bg-hoverColor dark:hover:bg-hoverDarkColor"
                      onClick={() => {
                        handleTokenSelect(item);
                      }}
                    >
                      <div className="self-stretch  h-[8.6vh] px-[2.4vh] py-[1.44vh] rounded-[2.5vh] flex-col justify-center items-center gap-[.96vh] flex">
                        <div className="self-stretch justify-start items-center gap-[1.92vh] inline-flex">
                          {/* yo */}
                          <div className="h-[3.84vh] justify-start items-center gap-[1.44vh] flex">
                            <div className="w-[3.84vh] h-[3.84vh] relative  border-white backdrop-blur-sm">
                              <div className="w-[2.88vh] h-[2.88vh] left-[4.01vh] top-[.5vh] absolute  " />
                              <img
                                alt="logourl"
                                src={item?.logoURI}
                                className="rounded border"
                              />
                            </div>

                            <div className="flex-col justify-start items-start gap-[.48vh] inline-flex">
                              <div className="justify-start items-center gap-[.96vh] inline-flex">
                                <div className="text-center font-montserrat dark:text-neutral-50 text-fontPrimaryColor md:text-base text-sm font-semibold  -tight">
                                  {item?.name}
                                </div>
                              </div>
                            </div>
                            <div className="w-[3.84vh] h-[3.84vh] relative" />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>,
          document.body
        )}
    </>
  );
};

export default ToSelectDropDown2;
