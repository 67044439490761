export function calculateEtaPF(c_T: string, c_PF: string, t_T: string, t_PF: string) {
  try {
    // Convert the string inputs to numbers
    const xNum = parseFloat('0.5');
    const c_TNum = parseFloat(c_T);
    const c_PFNum = parseFloat(c_PF);
    const t_TNum = parseFloat(t_T);
    const t_PFNum = parseFloat(t_PF);
    // console.log(xNum, c_TNum, c_PFNum, t_TNum, t_PFNum);
    // Proceed with the calculation using the parsed numbers
    const term1 = xNum * Math.log(c_TNum / c_PFNum);
    // console.log("term1",term1);
    const term2 = (1 - xNum) * Math.log(t_TNum / t_PFNum);
    // console.log("term2",term2);
  
    const exponent = term1 + term2;
    // console.log("exponent", exponent);
    const eta_PF = Math.exp(exponent) - 1;
    
    return eta_PF;
  }catch(err){
    console.error("error in calculating ETA", err);
    return 0;
  }
}
  