import React, {
  useState,
  useEffect,
  useRef,
  SetStateAction,
  Dispatch,
} from "react";
import { SearchCryptoLayout } from "../../../layouts/Search/SearchCryptoLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  AssetForTriaName,
  RootState,
  updateTokenInfo,
} from "../../../../../rx.core";
import { AssetContainer } from "./AssetContainer";
import { useFetchAssets } from "../../../../../../hooks";

interface Props {
  setShowUpdateChain: Dispatch<SetStateAction<boolean>>;
}

const SwitchChain: React.FC<Props> = ({ setShowUpdateChain }) => {
  const thirdDivRef = useRef<HTMLDivElement | null>(null);
  const { allAssets } = useFetchAssets();
  const dispatch = useDispatch();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        thirdDivRef.current &&
        !thirdDivRef.current.contains(event.target as Node)
      ) {
        setShowUpdateChain(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [thirdDivRef]);

  const handleTokenItemClick = (item: AssetForTriaName) => {
    dispatch(
      updateTokenInfo({
        qouteRate: item?.quoteRate,
        senderBalance: item?.balanceInTokens,
        tokenLogo: item?.logoUrl,
        chainName: item?.chainName,
        chainLogo: item?.chainLogo,
        tokenAddress: item?.tokenAddress || "",
        tokenName: item?.symbol,
        senderAddress: item?.wallet?.address,
        type: item?.wallet?.type,
        isNativeToken: item?.isNativeToken,
        isCustomToken: item?.isCustomToken,
      })
    );
    setShowUpdateChain(false);
  };

  return (
    <div className="w-[100vh] h-[100vh] fixed  z-[1000]">
      <div className="fixed h-[100vh] w-[53vh] top-0 left-1/2 transform -translate-x-1/2  bg-[#10101088] z-[800]" />
      <div
        ref={thirdDivRef}
        className="w-[50vh] h-[45.7vh] fixed bg-primaryColor dark:bg-primaryDarkColor z-[1000] top top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-[2.1vh] px-[1.4vh] border-[0.2vh] dark:border-outliningDark border-outlining overflow-hidden"
      >
        <SearchCryptoLayout />
        <div className="flex h-full flex-col gap-[0.5vh] overflow-y-scroll pb-[3vh]">
          {allAssets?.map((asset) => (
            <AssetContainer
              cryptoItem={asset}
              onClick={handleTokenItemClick}

              // onClick={handleTokenItemClick}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SwitchChain;
