// ReloadContext.tsx
import { useQueryClient } from "@tanstack/react-query";
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
} from "react";

interface ReloadContextType {
  reload: boolean;
  toggleReload: (val: boolean) => void;
}

const ReloadContext = createContext<ReloadContextType | undefined>(undefined);

interface ReloadProviderProps {
  children: ReactNode;
}

export const ReloadProvider = ({ children }: ReloadProviderProps) => {
  const [reload, setReload] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const toggleReload = useCallback(
    (val: boolean) => {
      setReload(val);
      if (val) {
        queryClient.resetQueries();
      }
    },
    [queryClient]
  );

  return (
    <ReloadContext.Provider value={{ reload, toggleReload }}>
      {children}
    </ReloadContext.Provider>
  );
};

export const useReload = (): ReloadContextType => {
  const context = useContext(ReloadContext);
  if (context === undefined) {
    throw new Error("useReload must be used within a ReloadProvider");
  }
  return context;
};
