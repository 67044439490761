import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, updateUserActivity } from "../package/rx.core";

const events = [
  // "mousedown",
  // "click",
  // "mouseup",
  // "focus",
  // "keyup",
  // "keydown",
  "mousemove",
  "touchstart",
  "touchmove",
];

export function useDetectInactivity({
  timeout = 30000,
}: { timeout?: number } = {}) {
  const inactivityTimer = useRef<number | null>(null);
  const userInactive = useSelector(
    (state: RootState) => state.User.isUserInactive
  );
  const dispatch = useDispatch();

  const setActive = useCallback(() => {
    console.log("fetch assets : user active");
    dispatch(updateUserActivity(false));
  }, [dispatch]);

  const setInactive = useCallback(() => {
    console.log("fetch assets : user inactive");
    dispatch(updateUserActivity(true));
  }, [dispatch]);

  const resetInactivityTimer = useCallback(() => {
    if (inactivityTimer.current) {
      window.clearTimeout(inactivityTimer.current);
    }
    inactivityTimer.current = window.setTimeout(setInactive, timeout);
  }, [setInactive, timeout]);

  const onActivity = useCallback(() => {
    if (userInactive) {
      setActive();
    }
    resetInactivityTimer();
  }, [userInactive, setActive, resetInactivityTimer]);

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, onActivity);
    });

    resetInactivityTimer();

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, onActivity);
      });
      if (inactivityTimer.current) {
        window.clearTimeout(inactivityTimer.current);
      }
    };
  }, [onActivity, resetInactivityTimer]);

  return userInactive;
}
