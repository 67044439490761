import { createPimlicoBundlerClient } from "permissionless/clients/pimlico";
// import { Etherspot } from "../sdk/etherspot";
import { ENTRYPOINT_ADDRESS_V06 } from "permissionless";
import { Hex, http } from "viem";
import { getExplorerUrl, getPimlicoChain } from "../constants";

export const waitForTransaction = async ({
  chainName,
  userOperationHash,
  eoaAddress,
  pimlicoApiKey = "05fb685d-466e-4e70-b6a9-1f55495312ee",
}: {
  chainName: string;
  userOperationHash: Hex;
  eoaAddress?: Hex;
  pimlicoApiKey?: string;
}) => {
  console.log("Querying for receipts...");
  let txHash;
  // if (chainName == "FUSE") {
  //   const etherspot = new Etherspot({ chainName, eoaAddress });
  //   txHash = await etherspot?.getTxnHash(userOperationHash);
  // } else {
  const chain = getPimlicoChain(chainName);
  const bundlerClient = createPimlicoBundlerClient({
    entryPoint: ENTRYPOINT_ADDRESS_V06,
    transport: http(
      `https://api.pimlico.io/v1/${chain}/rpc?apikey=${pimlicoApiKey}`
    ),
  });
  const receipt = await bundlerClient.waitForUserOperationReceipt({
    hash: userOperationHash as Hex,
  });
  txHash = receipt.receipt.transactionHash;
  // }

  console.log(
    `UserOperation included: ${getExplorerUrl(chainName)}/tx/${txHash}`
  );
  return txHash;
};
