import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, formatAddress } from "../../../../rx.core"; // Import your root reducer type
import { closeToast } from "../../../../rx.core"; // Import the closeToast action

export const Toast: React.FC = () => {
  const dispatch = useDispatch();
  const { isOpen, toastData } = useSelector((state: RootState) => state.toast);

  // Close the toast when clicked
  const handleClose = () => {
    dispatch(closeToast());
  };

  // if (!isOpen) {
  //   return null
  // }

  return (
    <>
      {toastData?.status === "error" ? (
        <div
          className={`fixed z-[1000] bottom-0 left-1/2 transform -translate-x-1/2 mb-[18vh] font-[500] px-[1.4vh] h-auto w-[48vh] py-[1.4vh] shadow-lg text-[#FFFFFFBF] items-center text-[1.67vh] flex justify-start bg-black transition-all rounded-[1.4vh] duration-500 gap-[1.4vh] ease-in-out bg-[#221213] `}
        >
          <div>
            <img src="/icons/danger.svg" />
          </div>
          <div onClick={handleClose}>{toastData?.title}</div>
        </div>
      ) : toastData?.status === "success" ? (
        <div
          className={`fixed z-[1000] bottom-0 left-1/2 transform -translate-x-1/2 mb-[18vh] font-[500] px-[1.4vh] h-auto w-[48vh] py-[1.4vh] shadow-lg text-[#FFFFFFBF] items-center text-[1.67vh] flex justify-start transition-all rounded-[1.4vh] duration-500 gap-[1.4vh] ease-in-out bg-[#17261c] `}
        >
          {/* <div>
            <img src="/icons/danger.svg" />
          </div> */}
          <div onClick={handleClose}>{toastData?.title}</div>
        </div>
      ) : null}

      {toastData?.status === "message" && (
        <div
          className={`fixed z-[1000] bottom-[50px] left-1/2 transform -translate-x-1/2 mb-[7vh] px-[2.4vh] h-auto w-auto py-[1vh] rounded-[1.4vh] shadow-lg text-[1.67vh] transition-all duration-500 ease-in-out bg-primaryDarkColor dark:bg-primaryColor`}
        >
          <div onClick={handleClose} className="flex flex-col px-[2.4vh]">
            <p className="dark:text-fontPrimaryColor text-fontPrimaryDarkColor text-center font-[600]">
              {toastData?.title}
            </p>
            {toastData?.desc ? (
              <p className="dark:text-fontLightColor text-fontLightColorDark font-[600]">
                {formatAddress(toastData?.desc || "")}
              </p>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};
