import { useEffect, useState } from "react";
import {
  RecentUser,
  RootState,
  updateCurrentRecipient,
  updateCurrentRecipientAddress,
  updatePreviousRoute,
  updateRecipientAvatar,
} from "../../../../../rx.core";
import { formatAddress } from "../../../../../rx.core";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
interface Props {
  recentUser: RecentUser;
  onClick?: () => void;
  liteMode?: boolean;
}

export const UserContainer: React.FC<Props> = ({ recentUser, liteMode }) => {
  const [randomColor, setRandomColor] = useState<string>();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const prevPath = useSelector(
    (store: RootState) => store.Route?.previousRoute
  );

  const handleClick = () => {
    if (recentUser?.background) {
      dispatch(updateCurrentRecipient(recentUser?.triaName));

      dispatch(
        updateRecipientAvatar({
          avatar: recentUser?.avatarUrl,
          background: recentUser?.background,
        })
      );
    } else {
      dispatch(updateCurrentRecipient(""));
      dispatch(updateCurrentRecipientAddress(recentUser?.triaName));
      dispatch(
        updateRecipientAvatar({
          char: recentUser?.triaName?.charAt(recentUser?.triaName?.length - 1),
          background: randomColor || "",
        })
      );
    }
    if (prevPath == "/assetHistory") {
      navigate("/home/sendCrypto/cryptoSendPage");
    } else if (prevPath == "/nfts/oneNft") {
      navigate("/home/sendCrypto/nftsend");
    } else {
      if (liteMode) {
        navigate(`/home/sendCrypto/conversations?lite=true`);
      } else {
        navigate(`/home/sendCrypto/conversations`);
      }

      dispatch(updatePreviousRoute(location?.pathname));
    }
  };

  const handleUserClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault();
    handleClick();
  };

  useEffect(() => {
    setRandomColor(
      `${"#" + (((1 << 24) * Math.random()) | 0).toString(16).padStart(6, "0")}`
    );
  }, []);

  if (liteMode) {
    return (
      <div
        className="w-full relative rounded-2xl p-3 flex items-center gap-3 cursor-pointer overflow-hidden"
        onClick={handleClick}
      >
        <div
          className="z-5 absolute w-full h-full left-0 top-0 rounded-lg"
          style={{
            background: recentUser?.background || randomColor,
            backgroundSize: "cover",
            backgroundPosition: "center",
            opacity: 0.15,
          }}
        ></div>
        <div
          className={`z-10 w-10 h-10 rounded-full flex items-center justify-center ${
            recentUser?.background ? "" : "text-white text-sm font-semibold"
          }`}
          style={{
            background: recentUser?.background || randomColor,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {recentUser?.background ? (
            <img
              src={recentUser?.avatarUrl}
              alt={recentUser?.triaName}
              className="w-8 h-8 rounded-full"
            />
          ) : (
            recentUser?.triaName
              ?.charAt(recentUser?.triaName?.length - 1)
              .toUpperCase()
          )}
        </div>
        <div className="z-10 flex-grow">
          <div className="text-sm font-semibold text-fontPrimaryColor dark:text-fontPrimaryDarkColor">
            {recentUser?.triaName?.length >= 13
              ? recentUser?.triaName?.slice(0, 8) + "...."
              : recentUser?.triaName?.split("@tria")[0]}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {recentUser?.background && (
        <div
          className="w-[24vh] relative h-[7.6vh] rounded-[2.5vh] px-[2.4vh] py-[1vh] flex-col justify-center items-start gap-[1vh] inline-flex overflow-hidden group"
          onClick={handleUserClick}
        >
          <div
            className=" absolute w-[26vh] left-[-2.4vh] h-[7.6vh] rounded-[2.4vh] opacity-[0.15] group-hover:opacity-60"
            style={{ backgroundImage: recentUser?.background }}
          ></div>
          <div
            className="w-[44.7vh] h-[5.7vh] justify-start items-center gap-[1.4vh] inline-flex cursor-pointer z-20"
            style={{ opacity: 1 }}
          >
            <div
              className="h-[4.76vh] w-[4.76vh] rounded-[2.4vh] flex justify-center items-center"
              style={{ backgroundImage: recentUser?.background }}
            >
              <img
                src={recentUser?.avatarUrl}
                className="w-[3.8vh] h-[3.8vh] rounded-[2.7vh]"
              />
            </div>
            <div
              className={`w-[38.5vh] self-stretch justify-start items-center gap-[1vh] flex`}
            >
              <div className="text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-xs font-semibold leading-tight">
                {recentUser?.triaName?.length >= 13 ? (
                  <span>{recentUser?.triaName?.slice(0, 8) + "...."}</span>
                ) : (
                  <span>{recentUser?.triaName?.split("@tria")}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {!recentUser?.background && (
        <div
          className="w-[24vh] relative h-[7.6vh] rounded-[2.5vh] px-[2.4vh] py-[1vh] flex-col justify-center items-start gap-[1vh] inline-flex overflow-hidden group"
          onClick={handleUserClick}
        >
          <div
            className="absolute w-[26vh] left-[-2.4vh] h-[7.6vh] rounded-[2.4vh] opacity-[0.15] group-hover:opacity-60"
            style={{ backgroundColor: `${randomColor}` }}
          ></div>
          <div
            className="w-[44.7vh] h-[5.7vh] justify-start items-center gap-[1.4vh] inline-flex cursor-pointer z-20"
            style={{ opacity: 1 }}
          >
            <div
              className="h-[4.76vh] w-[4.76vh] rounded-[2.4vh] flex justify-center items-center"
              style={{ backgroundColor: `${randomColor}` }}
            >
              <p className="text-[2vh] font-[600] text-white">
                {recentUser?.triaName
                  ?.charAt(recentUser?.triaName?.length - 1)
                  .toUpperCase()}
              </p>
            </div>
            <div className="w-[38.5vh] self-stretch justify-start items-center gap-[1vh] flex">
              <div className="text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[2vh] font-semibold leading-tight">
                {formatAddress(recentUser?.triaName)}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
