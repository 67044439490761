import { Connection } from "@solana/web3.js";
import { rpcUrl } from ".";

export const waitForTransaction = async (
  txnId: string,
  confirmations?: number | undefined,
  _timeout?: number | undefined
): Promise<boolean> => {
  const connection = new Connection(rpcUrl.mainnet, {
    commitment: "confirmed",
  });
  if (confirmations && confirmations > 10) {
    await connection.confirmTransaction(txnId, "finalized");
  } else {
    await connection.confirmTransaction(txnId, "confirmed");
  }
  return true;
};
