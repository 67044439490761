import { useTriaUser } from "../package/ui.common/src/contexts/tria-user-provider/useTriaUser";

export const useResolveTriaName = () => {
  const { getUserByAddress } = useTriaUser();
  const getTriaName = async (chainName: string, address: string) => {
    try {
      const triaName = await getUserByAddress(address, chainName);
      return triaName;
    } catch (err) {
      console.log(err);
    }
  };
  return getTriaName;
};
