import axios from "axios";
// import { setupCache } from "axios-cache-interceptor";
class BackendService {
    constructor(apiUrl) {
        this.triaAxios = axios.create({
            baseURL: `${apiUrl}/api/v2/`,
            withCredentials: true,
        });
        // Request Interceptor
        this.triaAxios.interceptors.request.use((config) => {
            console.log("Request made with", config);
            return config;
        }, (error) => {
            console.error("Request error:", error);
            return Promise.reject(error);
        });
        // Response Interceptor
        this.triaAxios.interceptors.response.use((response) => {
            console.log("intercepting responseee");
            if (response.status === 401) {
                console.log("intercepting 401 response");
                // Dispatch a custom event when a 401 status code is received
                const unauthorizedEvent = new Event("unauthorized");
                window.dispatchEvent(unauthorizedEvent);
            }
            return response;
        }, (error) => {
            if (error.response) {
                console.error("Response error with status:", error.response.status);
                if (error.response.status === 401) {
                    console.log("intercepting 401 response");
                    // Dispatch a custom event when a 401 status code is received
                    const unauthorizedEvent = new Event("unauthorized");
                    window.dispatchEvent(unauthorizedEvent);
                }
            }
            else if (error.request) {
                console.error("No response was received:", error.request);
            }
            else {
                console.error("Error setting up the request:", error.message);
            }
            return Promise.reject(error);
        });
    }
}
export default BackendService;
