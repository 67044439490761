import { useTriaUser } from "../package/ui.common/src/contexts/tria-user-provider/useTriaUser";

import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { UserPrefResponse } from "@tria-sdk/core";
import { useTriaName } from "./useTriaName";

export const useUserPrefs = (
  params?: Omit<UseQueryOptions<UserPrefResponse>, "queryKey" | "queryFn">
) => {
  const { getUserPrefs } = useTriaUser();
  const { triaName } = useTriaName();

  const query = useQuery<UserPrefResponse>({
    queryKey: ["getUserPrefs", triaName],
    queryFn: async () => {
      console.log("getUserPrefs");
      const resp = await getUserPrefs();
      console.log("getUserPrefs resp", resp);
      if (resp instanceof Error) {
        return Promise.reject(new Error(resp.message));
      }

      return resp;
    },
    staleTime: 5 * 10 * 1000,
    enabled: (params?.enabled ?? true) && !!triaName,
    ...params,
  });

  return query;
};
