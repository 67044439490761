import { chainIds } from "../types";
import {
  TRIA_WALLET_SELECTED_CHAIN,
  chainIdToChainName,
  eventTypes,
} from "../utils/constants";
import {
  getChainFromWagmiStore,
  triaConnected,
  wagmiConnected,
} from "../utils/helper";
export function useSwitchChain() {
  const switchChain = (chainName: string) => {
    if (window.parent && document.referrer) {
      const message = JSON.stringify({
        success: true,
        type: eventTypes.switchChain,
        data: { chainName },
      });
      window.parent.postMessage(message, document.referrer);
    }
  };

  return { switchChain };
}

export function useChainName(): { chainName: string | null } {
  const wagmiC = wagmiConnected();
  const chainId = getChainFromWagmiStore() as chainIds;
  if (triaConnected()) {
    const chainName = window.localStorage.getItem(TRIA_WALLET_SELECTED_CHAIN);
    return { chainName };
  } else if (wagmiC) {
    const wagmiStore = localStorage.getItem("wagmi.store");
    if (wagmiStore && chainId) {
      const chainName = chainIdToChainName[chainId];
      return { chainName };
    } else return { chainName: null };
  } else return { chainName: null };
}
