import { Route } from "@lifi/sdk";

export function calculateLiFiCost(routes: Route[]): string {
  try {
    const totalCost = (
      parseFloat(routes[0].fromAmountUSD) +
      parseFloat(routes[0].gasCostUSD || "0.0502")
    ).toFixed(4);
    return totalCost;
  } catch (err) {
    console.error(err);
    return "0";
  }
}
